import React from 'react';
import { Button, ScreenContainer, TextInput, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { Image, Keyboard, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { email: null, name: null, password: null };

const DeleteAccountScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [emailValue, setEmailValue] = React.useState(
    props.route?.params?.email ?? defaultProps.email
  );
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [nameValue, setNameValue] = React.useState(
    props.route?.params?.name ?? defaultProps.name
  );
  const [passwordValue, setPasswordValue] = React.useState(
    props.route?.params?.password ?? defaultProps.password
  );
  const validForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneNumberRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;

    if (nameValue.length < 1) {
      setErrorMessage('Please enter your name');
      return false;
    }

    if (emailValue.length < 1) {
      setErrorMessage('Please enter email');
      return false;
    }

    if (!emailRegex.test(emailValue.trim())) {
      setErrorMessage('Please enter valid email');
      return false;
    }

    if (passwordValue.length < 1) {
      setErrorMessage('Please enter password');
      return false;
    }

    setErrorMessage('');
    return true;
  };
  const xanoServiceDeleteAccountDELETE =
    XanoServiceApi.useDeleteAccountDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      trackScreen(segment, 'Delete Account');
      setEmailValue('');
      setPasswordValue('');
      if (!isLoggedIn(Variables)) {
        return;
      }
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);
  React.useEffect(() => {
    try {
      if (isFocused) {
        return;
      }
      setErrorMessage('');
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.White },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['Header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['Header'].style,
          dimensions.width
        )}
      >
        {/* AbsoluteTitle */}
        <View
          {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
            dimensions.width
          )}
        >
          <Image
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            resizeMode={'contain'}
            source={imageSource(Images['MailboxxGreenLogo'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { height: 30, width: 120 }
              ),
              dimensions.width
            )}
          />
        </View>
      </View>
      {/* Main */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: 'center',
            flex: [
              { minWidth: Breakpoints.Desktop, value: 1 },
              { minWidth: Breakpoints.Mobile, value: 1 },
            ],
            justifyContent: 'flex-start',
            marginLeft: 20,
            marginRight: 20,
            marginTop: 30,
            maxWidth: 1024,
          },
          dimensions.width
        )}
      >
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              fontSize: 32,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {'Delete Account'}
        </Text>
        {/* Warning Text */}
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: theme.colors.text.strong,
              fontFamily: 'Poppins_400Regular',
              marginBottom: 12,
              marginTop: 12,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {'We are sorry to see you leave\nIf you delete your account:'}
        </Text>
        {/* Text 2 */}
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: theme.colors.text.strong,
            }),
            dimensions.width
          )}
        >
          {
            '* You will loose all your data\n* If you have available balance on your account, they will also be erased and can not be accessed \n* This action can not be un-done.'
          }
        </Text>
        {/* NameInput */}
        <TextInput
          autoCapitalize={'none'}
          autoCorrect={true}
          changeTextDelay={500}
          onChangeText={newNameInputValue => {
            try {
              setNameValue(newNameInputValue);
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          }}
          webShowOutline={true}
          {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
          keyboardType={'email-address'}
          placeholder={'Please enter your name'}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextInputStyles(theme)['Text Input'].style,
              { marginTop: 40, paddingLeft: 8 }
            ),
            dimensions.width
          )}
          value={nameValue}
        />
        {/* EmailInput */}
        <TextInput
          autoCapitalize={'none'}
          autoCorrect={true}
          changeTextDelay={500}
          onChangeText={newEmailInputValue => {
            try {
              setEmailValue(newEmailInputValue);
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          }}
          webShowOutline={true}
          {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
          keyboardType={'email-address'}
          placeholder={'Please enter your email'}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextInputStyles(theme)['Text Input'].style,
              { marginTop: 20, paddingLeft: 8 }
            ),
            dimensions.width
          )}
          value={emailValue}
        />
        {/* PasswordInput */}
        <TextInput
          autoCapitalize={'none'}
          autoCorrect={true}
          changeTextDelay={500}
          onChangeText={newPasswordInputValue => {
            try {
              setPasswordValue(newPasswordInputValue);
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          }}
          webShowOutline={true}
          {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
          placeholder={'Please enter your password'}
          secureTextEntry={true}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextInputStyles(theme)['Text Input'].style,
              { marginTop: 20, paddingLeft: 8 }
            ),
            dimensions.width
          )}
          value={passwordValue}
        />
        <>
          {!errorMessage ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App.CustomGray,
                  borderRadius: 12,
                  marginTop: '10%',
                  padding: 10,
                },
                dimensions.width
              )}
            >
              {/* errorText */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: theme.colors.background.danger,
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {errorMessage}
              </Text>
            </View>
          )}
        </>
        {/* Warning Text 2 */}
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: theme.colors.text.strong,
              fontFamily: 'Poppins_400Regular',
              marginBottom: 12,
              marginTop: 12,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {
            'Since this process is manual, it might take some time to get your account deleted.\n'
          }
        </Text>
        {/* DeleteButton */}
        <Button
          iconPosition={'left'}
          onPress={() => {
            const handler = async () => {
              try {
                const isValidForm = validForm();
                Keyboard.dismiss();
                if (!isValidForm) {
                  return;
                }
                setLoading(true);
                const loginResult = (
                  await xanoServiceDeleteAccountDELETE.mutateAsync({
                    email: emailValue,
                    name: nameValue,
                    password: passwordValue,
                  })
                )?.json;
                setLoading(false);
                setErrorMessage(loginResult?.message);
                if (loginResult?.message) {
                  return;
                }
                trackAction(Variables, segment, 'Deleted Account', emailValue);
                setGlobalVariableValue({
                  key: 'USER_DATA',
                  value: '',
                });
                setGlobalVariableValue({
                  key: 'AuthToken',
                  value: '',
                });
                setGlobalVariableValue({
                  key: 'PRIMARY_ADDRESS',
                  value: '',
                });
                setPasswordValue('');
                setEmailValue('');
                setNameValue('');
                navigation.navigate('AuthLoginScreen');
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            };
            handler();
          }}
          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
          loading={loading}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              { marginTop: 20 }
            ),
            dimensions.width
          )}
          title={'Delete Account'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(DeleteAccountScreen);
