import React from 'react';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';
import waitUtil from '../utils/wait';

const defaultProps = {
  token:
    'eyJhbGciOiJBMTI4S1ciLCJlbmMiOiJBMTI4R0NNIiwiemlwIjoiREVGIn0.e4EmkxQTno2-EapcXCrK4lyPAnoQUfeS.X8zLD-E7XLbr_J7M.C6h3MpFfTIlPUrl1NAqBJmDUh0KiNS4hkz3nAkXPNt7UhEUucbNfn_pUQhJhN5HYCkzUbX5QqQ.peY9QfihAPjHX71R07NM-w',
};

const UnsubscribeScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [emailValue, setEmailValue] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [nameValue, setNameValue] = React.useState(null);
  const [passwordValue, setPasswordValue] = React.useState(null);
  const validForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneNumberRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;

    if (nameValue.length < 1) {
      setErrorMessage('Please enter your name');
      return false;
    }

    if (emailValue.length < 1) {
      setErrorMessage('Please enter email');
      return false;
    }

    if (!emailRegex.test(emailValue.trim())) {
      setErrorMessage('Please enter valid email');
      return false;
    }

    if (passwordValue.length < 1) {
      setErrorMessage('Please enter password');
      return false;
    }

    setErrorMessage('');
    return true;
  };
  const xanoServiceToggleEmailPOST = XanoServiceApi.useToggleEmailPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      trackScreen(segment, 'Delete Account');
      setEmailValue('');
      setPasswordValue('');
      if (!isLoggedIn(Variables)) {
        return;
      }
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);
  React.useEffect(() => {
    try {
      if (isFocused) {
        return;
      }
      setErrorMessage('');
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.White },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['Header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['Header'].style,
          dimensions.width
        )}
      >
        {/* AbsoluteTitle */}
        <View
          {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
            dimensions.width
          )}
        >
          <Image
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            resizeMode={'contain'}
            source={imageSource(Images['MailboxxGreenLogo'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { height: 30, width: 120 }
              ),
              dimensions.width
            )}
          />
        </View>
      </View>

      <XanoServiceApi.FetchGetEmailToUnsubscribePOST
        token={props.route?.params?.token ?? defaultProps.token}
      >
        {({ loading, error, data, refetchGetEmailToUnsubscribe }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return (
              <>
                {!errorMessage ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App.CustomGray,
                        borderRadius: 12,
                        marginTop: '10%',
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* errorText */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: theme.colors.background.danger,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {errorMessage}
                    </Text>
                  </View>
                )}
              </>
            );
          }

          return (
            <>
              {/* Main */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignSelf: 'center',
                    flex: [
                      { minWidth: Breakpoints.Desktop, value: 1 },
                      { minWidth: Breakpoints.Mobile, value: 1 },
                    ],
                    justifyContent: 'center',
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 30,
                    maxWidth: 1024,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { fontSize: 32, textAlign: 'center' }
                    ),
                    dimensions.width
                  )}
                >
                  {'Unsubscribing...\n'}
                </Text>
                {/* Warning Text */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                        marginBottom: 12,
                        marginTop: 12,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Are you sure you want to unsubscribe '}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      dimensions.width
                    )}
                  >
                    {fetchData?.email}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        dimensions.width
                      )}
                    >
                      {'  from the mailing list?'}
                    </Text>
                  </Text>
                </Text>
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setGlobalVariableValue({
                          key: 'AuthToken',
                          value: fetchData?.authToken,
                        });
                        await waitUtil({ milliseconds: 1000 });
                        const result = (
                          await xanoServiceToggleEmailPOST.mutateAsync()
                        )?.json;
                        navigation.navigate('AuthLoginScreen');
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    dimensions.width
                  )}
                  title={'Unsubscribe'}
                />
              </View>
            </>
          );
        }}
      </XanoServiceApi.FetchGetEmailToUnsubscribePOST>
    </ScreenContainer>
  );
};

export default withTheme(UnsubscribeScreen);
