import React from 'react';
import {
  Button,
  Link,
  Pressable,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { Image, Keyboard, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackAction from '../global-functions/trackAction';
import trackIdentity from '../global-functions/trackIdentity';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const AuthLoginScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [emailValue, setEmailValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [passwordValue, setPasswordValue] = React.useState('');
  const validForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneNumberRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;

    if (emailValue.length < 1) {
      setErrorMessage('Please enter email');
      return false;
    }

    if (!emailRegex.test(emailValue.trim())) {
      setErrorMessage('Please enter valid email');
      return false;
    }

    if (passwordValue.length < 1) {
      setErrorMessage('Please enter password');
      return false;
    }

    setErrorMessage('');
    return true;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      trackScreen(segment, 'Login');
      setEmailValue('');
      setPasswordValue('');
      if (!isLoggedIn(Variables)) {
        return;
      }
      if (Constants['USER_DATA']?.role === 'admin') {
        navigation.navigate('AdminDashboardScreen');
      } else {
        if (Constants['USER_DATA']?.role === 'business') {
          navigation.navigate('MyPromotionsScreen');
        } else {
          navigation.navigate('InboxScreen');
        }
      }
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);
  React.useEffect(() => {
    try {
      if (isFocused) {
        return;
      }
      setErrorMessage('');
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.White },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['Header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['Header'].style,
          dimensions.width
        )}
      >
        {/* AbsoluteTitle */}
        <View
          {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
            dimensions.width
          )}
        >
          <Image
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            resizeMode={'contain'}
            source={imageSource(Images['MailboxxGreenLogo'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { height: 30, width: 120 }
              ),
              dimensions.width
            )}
          />
        </View>
      </View>
      {/* Main */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: 'center',
            flex: [
              { minWidth: Breakpoints.Desktop, value: 1 },
              { minWidth: Breakpoints.Mobile, value: 1 },
            ],
            justifyContent: 'center',
            marginLeft: 20,
            marginRight: 20,
            marginTop: 30,
            maxWidth: 1024,
          },
          dimensions.width
        )}
      >
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              fontSize: 32,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {'Login'}
        </Text>
        {/* EmailInput */}
        <TextInput
          autoCapitalize={'none'}
          autoCorrect={true}
          changeTextDelay={500}
          onChangeText={newEmailInputValue => {
            try {
              setEmailValue(newEmailInputValue);
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          }}
          webShowOutline={true}
          {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
          keyboardType={'email-address'}
          placeholder={'Email'}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextInputStyles(theme)['Text Input'].style,
              { marginTop: 40, paddingLeft: 8 }
            ),
            dimensions.width
          )}
          value={emailValue}
        />
        {/* PasswordInput */}
        <TextInput
          autoCapitalize={'none'}
          autoCorrect={true}
          changeTextDelay={500}
          onChangeText={newPasswordInputValue => {
            try {
              setPasswordValue(newPasswordInputValue);
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          }}
          webShowOutline={true}
          {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
          placeholder={'Password'}
          secureTextEntry={true}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextInputStyles(theme)['Text Input'].style,
              { marginTop: 20, paddingLeft: 8 }
            ),
            dimensions.width
          )}
          value={passwordValue}
        />
        <>
          {!errorMessage ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App.CustomGray,
                  borderRadius: 12,
                  marginTop: '10%',
                  padding: 10,
                },
                dimensions.width
              )}
            >
              {/* errorText */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: theme.colors.background.danger,
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {errorMessage}
              </Text>
            </View>
          )}
        </>
        {/* Log In Button */}
        <Button
          iconPosition={'left'}
          onPress={() => {
            const handler = async () => {
              try {
                const isValidForm = validForm();
                Keyboard.dismiss();
                if (!isValidForm) {
                  return;
                }
                setLoading(true);
                const loginResult = (
                  await XanoServiceApi.loginPOST(Constants, {
                    email: emailValue,
                    password: passwordValue,
                  })
                )?.json;
                setLoading(false);
                setErrorMessage(loginResult?.message);
                if (loginResult?.message) {
                  trackAction(Variables, segment, 'Login Failed', emailValue);
                }
                if (loginResult?.message) {
                  return;
                }
                trackIdentity(segment, emailValue);
                trackAction(Variables, segment, 'Login Successful', undefined);
                setGlobalVariableValue({
                  key: 'USER_DATA',
                  value: loginResult?.user,
                });
                setGlobalVariableValue({
                  key: 'AuthToken',
                  value: loginResult?.authToken,
                });
                setGlobalVariableValue({
                  key: 'PRIMARY_ADDRESS',
                  value: loginResult?.primary_address,
                });
                console.log(loginResult?.user);
                if (!loginResult?.primary_address) {
                  navigation.navigate('AddAddressScreen', {
                    isOnboarding: true,
                  });
                }
                if (!loginResult?.primary_address) {
                  return;
                }
                setPasswordValue('');
                setEmailValue('');
                if (loginResult?.user?.role === 'user') {
                  navigation.navigate('InboxScreen');
                }
                if (loginResult?.user?.role === 'business') {
                  navigation.navigate('MyPromotionsScreen');
                }
                if (loginResult?.user?.role === 'admin') {
                  navigation.navigate('AdminDashboardScreen');
                }
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            };
            handler();
          }}
          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
          loading={loading}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              { marginTop: 20 }
            ),
            dimensions.width
          )}
          title={'Login'}
        />
        {/* Terms and Conditions Text */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'space-between',
              alignItems: 'flex-start',
              alignSelf: 'auto',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              marginTop: 20,
            },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: theme.colors.text.strong,
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {'By signing in, you agree to our'}
          </Text>

          <Pressable
            onPress={() => {
              try {
                navigation.navigate('TermsandConditionsScreen');
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            }}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { fontFamily: 'Poppins_300Light', marginLeft: 4 }
                ),
                dimensions.width
              )}
            >
              {'Terms & Conditions'}
            </Text>
          </Pressable>

          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: theme.colors.text.strong,
                marginLeft: 4,
              }),
              dimensions.width
            )}
          >
            {'and'}
          </Text>
          {/* Pressable 2 */}
          <Pressable
            onPress={() => {
              try {
                navigation.navigate('PrivacyPolicyScreen');
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            }}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App.customGreen,
                    fontFamily: 'Poppins_300Light',
                    marginLeft: 4,
                  }
                ),
                dimensions.width
              )}
            >
              {'Privacy Policy'}
            </Text>
          </Pressable>
        </View>
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              flex: 1,
              justifyContent: 'space-between',
              marginBottom: 50,
              marginTop: 30,
            },
            dimensions.width
          )}
        >
          {/* GoToForgotPasswordButton */}
          <Link
            accessible={true}
            onPress={() => {
              try {
                navigation.navigate('AuthForgetPasswordScreen');
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            }}
            selectable={false}
            {...GlobalStyles.LinkStyles(theme)['Link'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'].style, {
                alignSelf: 'center',
                fontSize: 16,
              }),
              dimensions.width
            )}
            title={'Forgot Password?'}
          />
          {/* GoToSignUpButton */}
          <Link
            accessible={true}
            onPress={() => {
              try {
                navigation.navigate('AuthSelectSignupTypeScreen');
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            }}
            selectable={false}
            {...GlobalStyles.LinkStyles(theme)['Link'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'].style, {
                alignSelf: 'center',
                fontSize: 16,
                textAlign: 'center',
              }),
              dimensions.width
            )}
            title={'Sign Up'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AuthLoginScreen);
