import React from 'react';
import { Link, withTheme } from '@draftbit/ui';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import * as WebBrowser from 'expo-web-browser';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as UploadCSV from '../custom-files/UploadCSV';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { onComplete: () => {} };

const BulkImportBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
          flex: 1,
          justifyContent: [
            { minWidth: Breakpoints.Desktop, value: 'center' },
            { minWidth: Breakpoints.Mobile, value: 'space-between' },
          ],
          marginLeft: 20,
          marginRight: 20,
          maxWidth: 1024,
        },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 30,
            padding: 16,
          },
          dimensions.width
        )}
      >
        {/* Title */}
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['HeaderTitleText'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['HeaderTitleText'].style,
            dimensions.width
          )}
        >
          {'Import Contacts'}
        </Text>
      </View>

      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: [
              { minWidth: Breakpoints.Tablet, value: 'center' },
              { minWidth: Breakpoints.Mobile, value: 'center' },
            ],
            flex: 1,
            justifyContent: 'center',
            marginTop: 20,
          },
          dimensions.width
        )}
      >
        <Image
          {...GlobalStyles.ImageStyles(theme)['Image'].props}
          resizeMode={'contain'}
          source={imageSource(Images['contactimport'])}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'].style, {
              height: [
                { minWidth: Breakpoints.Mobile, value: null },
                {
                  minWidth: Breakpoints.Mobile,
                  value: dimensions.width * (50 / 100),
                },
              ],
              width: [
                { minWidth: Breakpoints.Mobile, value: null },
                {
                  minWidth: Breakpoints.Mobile,
                  value: dimensions.width * (70 / 100),
                },
              ],
            }),
            dimensions.width
          )}
        />
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              fontFamily: 'Poppins_600SemiBold',
              fontSize: 20,
              margin: 25,
            }),
            dimensions.width
          )}
        >
          {'Contacts Datasheet'}
        </Text>
        {/* Text 2 */}
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Text'].style,
            dimensions.width
          )}
        >
          {
            "You can Import your customers into Mailboxx and start sending direct promotions to their Inbox's\n\nEnsure your CSV file is formatted correctly. Each row should represent a contact with columns for first name, last name, email, and phone number. After uploading, your contacts will be imported into the system. "
          }
          <Link
            accessible={true}
            onPress={() => {
              const handler = async () => {
                try {
                  await WebBrowser.openBrowserAsync(
                    `${Constants['SAMPLE_CSV_URL']}`
                  );
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              };
              handler();
            }}
            selectable={false}
            {...GlobalStyles.LinkStyles(theme)['Link'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'].style, {
                fontFamily: 'Poppins_600SemiBold',
                textDecorationLine: 'underline',
              }),
              dimensions.width
            )}
            title={'Example CVS'}
          />
        </Text>
        <Utils.CustomCodeErrorBoundary>
          <UploadCSV.Wrapper
            theme={theme}
            onComplete={() => {
              props.onComplete?.();
            }}
          />
        </Utils.CustomCodeErrorBoundary>
      </View>
    </View>
  );
};

export default withTheme(BulkImportBlock);
