import React from 'react';
import { ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { View } from 'react-native';
import BulkImportBlock from '../components/BulkImportBlock';
import NarrowHeaderWithDrawerBlock from '../components/NarrowHeaderWithDrawerBlock';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as DrawerScreen from '../custom-files/DrawerScreen';
import isLoggedIn from '../global-functions/isLoggedIn';
import showToastMessage from '../global-functions/showToastMessage';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { ur: null };

const ContactsImportScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      setGlobalVariableValue({
        key: 'SHOW_DRAWER',
        value: false,
      });
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock currentScreen={'contact_import'} />
        )}
      </>
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <NarrowHeaderWithDrawerBlock title={'Contacts Import'} />
        )}
      </>
      {/* DrawerNew */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <Utils.CustomCodeErrorBoundary>
            <DrawerScreen.DrawerView
              currentScreen="contact_import"
              navigation={props.navigation}
            />
          </Utils.CustomCodeErrorBoundary>
        )}
      </>
      <View>
        <BulkImportBlock
          onComplete={() => {
            try {
              showToastMessage(
                'Import Started',
                'success',
                undefined,
                undefined
              );
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          }}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ContactsImportScreen);
