import * as Lib from '../custom-files/Lib';

const showToastMessage = (message, type, duration, backgroundColor) => {
  if (!message) return;
  if (!type) type = 'success';
  if (!duration) duration = 3000;

  if (type === 'success') {
    Lib.showMessage({
      message: message,
      type,
      duration,
      color: '#006400',
      backgroundColor: backgroundColor || '#C4FDDB',
      floating: false,
    });
  } else if (type === 'danger') {
    Lib.showMessage({
      message: message,
      type,
      duration,
      color: '#FFFFFF',
      backgroundColor: backgroundColor || '#FFC4C4',
      floating: false,
    });
  } else if (type === 'info') {
    Lib.showMessage({
      message: message,
      type,
      duration,
      color: '#333333',
      backgroundColor: backgroundColor || '#E6F3FF',
      floating: false,
    });
  } else if (type === 'warning') {
    Lib.showMessage({
      message: message,
      type,
      duration,
      color: '#8B4000',
      backgroundColor: backgroundColor || '#FFF3CD',
      floating: false,
    });
  }
};

export default showToastMessage;
