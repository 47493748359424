import { showMessage } from 'react-native-flash-message';

export const myMessage = ({
  message,
  type = 'success',
  duration = 3000,
  backgroundColor,
}) => {
  if (type === 'success') {
    showMessage({
      message: message,
      type,
      duration,
      color: '#006400',
      backgroundColor: backgroundColor || '#C4FDDB',
      floating: true,
    });
  } else if (type === 'danger') {
    showMessage({
      message: message,
      type,
      duration,
      color: '#FFFFFF',
      backgroundColor: backgroundColor || '#FFC4C4',
      floating: true,
    });
  } else if (type === 'info') {
    showMessage({
      message: message,
      type,
      duration,
      color: '#333333',
      backgroundColor: backgroundColor || '#E6F3FF',
      floating: true,
    });
  } else if (type === 'warning') {
    showMessage({
      message: message,
      type,
      duration,
      color: '#8B4000',
      backgroundColor: backgroundColor || '#FFF3CD',
      floating: true,
    });
  }
};
