import React from 'react';
import { ScreenContainer, withTheme } from '@draftbit/ui';
import { H2 } from '@expo/html-elements';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as StripeServiceApi from '../apis/StripeServiceApi.js';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { connected_account_id: 1 };

const CallbackScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const segment = useAnalytics();
  const [switchValue, setSwitchValue] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      trackScreen(segment, 'Get in Contact');
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: [
            { minWidth: Breakpoints.Mobile, value: palettes.App.White },
            { minWidth: Breakpoints.Desktop, value: palettes.App.CustomGray },
          ],
        },
        dimensions.width
      )}
    >
      {/* Body */}
      <View
        {...GlobalStyles.ViewStyles(theme)['ResponsiveBody'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ViewStyles(theme)['ResponsiveBody'].style,
            { justifyContent: 'center' }
          ),
          dimensions.width
        )}
      >
        <H2
          selectable={false}
          {...GlobalStyles.H2Styles(theme)['H2'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.H2Styles(theme)['H2'].style, {
              color: theme.colors.branding.primary,
              fontFamily: 'Poppins_400Regular',
              fontSize: 28,
              marginBottom: 24,
              marginTop: 48,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {'Stripe Connect Account Integrated Successfully!'}
        </H2>
        <>
          {!(
            props.route?.params?.connected_account_id ??
            defaultProps.connected_account_id
          ) ? null : (
            <StripeServiceApi.FetchCreateConnectedAccountReturnGET
              id={
                props.route?.params?.connected_account_id ??
                defaultProps.connected_account_id
              }
            >
              {({
                loading,
                error,
                data,
                refetchCreateConnectedAccountReturn,
              }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return (
                    <>
                      {/* View 2 */}
                      <View>
                        {/* Label */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { fontSize: 18, textAlign: 'center' }
                            ),
                            dimensions.width
                          )}
                        >
                          {
                            'Account was successfully integrated. However something went wrong while updating the database. Please get in contact with help@mailboxx.app'
                          }
                        </Text>
                      </View>
                    </>
                  );
                }

                return (
                  <View>
                    {/* Label */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { fontSize: 18, textAlign: 'center' }
                        ),
                        dimensions.width
                      )}
                    >
                      {'You can now close this window'}
                    </Text>
                  </View>
                );
              }}
            </StripeServiceApi.FetchCreateConnectedAccountReturnGET>
          )}
        </>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(CallbackScreen);
