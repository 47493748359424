import React from 'react';
import { MapView } from '@draftbit/maps';
import {
  Button,
  Icon,
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleKeyboardAwareScrollView,
  SimpleStyleScrollView,
  Surface,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GoogleGeocodingApi from '../apis/GoogleGeocodingApi.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import isLoggedIn from '../global-functions/isLoggedIn';
import subtractPadding from '../global-functions/subtractPadding';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import getLocationUtil from '../utils/getLocation';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { isFirstAddress: true, isOnboarding: true };

const AddAddressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const [addressLocation, setAddressLocation] = React.useState({});
  const [cityValue, setCityValue] = React.useState('');
  const [countryValue, setCountryValue] = React.useState('');
  const [defaultAddressTitles, setDefaultAddressTitles] = React.useState([
    'Home',
    'Work',
    'Other',
  ]);
  const [defaultBusinessAddressTitles, setDefaultBusinessAddressTitles] =
    React.useState(['Headquarters', 'Main', 'Branch']);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [houseNumberValue, setHouseNumberValue] = React.useState('');
  const [isInitialLoad, setIsInitialLoad] = React.useState(true);
  const [isSavingAddress, setIsSavingAddress] = React.useState(false);
  const [isUpdatingAddress, setIsUpdatingAddress] = React.useState(false);
  const [roadValue, setRoadValue] = React.useState('');
  const [searchBoxFocused, setSearchBoxFocused] = React.useState(false);
  const [searchDisplayValue, setSearchDisplayValue] = React.useState('');
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedAccountId, setSelectedAccountId] = React.useState('');
  const [stateValue, setStateValue] = React.useState('');
  const [titleBoxFocued, setTitleBoxFocued] = React.useState(false);
  const [titleValue, setTitleValue] = React.useState('');
  const [zipcodeValue, setZipcodeValue] = React.useState('');
  const getX = (windowWidth, width) => {
    return Math.min(1024, windowWidth) / 2 - width / 2;
  };

  const subtractPadding = (windowWidth, padding) => {
    return windowWidth - padding * 2;
  };

  const formatOptions = arr => {
    // [{"id":1,"created_at":1696509259846,"user_id":36,"display_name":"Insufficient Funds","institution_name":"StripeBank","stripe_account_id":"fca_1NxpBPKZLTSiovpVHifi7eXr","subcategory":"checking","status":"active"},{"id":2,"created_at":1696509259852,"user_id":36,"display_name":"Account Closed","institution_name":"StripeBank","stripe_account_id":"fca_1NxpBPKZLTSiovpVMPTBo8u6","subcategory":"checking","status":"active"},{"id":3,"created_at":1696509259857,"user_id":36,"display_name":"Failure","institution_name":"StripeBank","stripe_account_id":"fca_1NxpBPKZLTSiovpVek9deRxc","subcategory":"checking","status":"active"},{"id":4,"created_at":1696509259861,"user_id":36,"display_name":"High Balance","institution_name":"StripeBank","stripe_account_id":"fca_1NxpBPKZLTSiovpVhs5Ucb1k","subcategory":"checking","status":"active"},{"id":5,"created_at":1696509259866,"user_id":36,"display_name":"Success","institution_name":"StripeBank","stripe_account_id":"fca_1NxpBPKZLTSiovpVmFA62tth","subcategory":"checking","status":"active"},{"id":6,"created_at":1696509259870,"user_id":36,"display_name":"Debit Not Authorized","institution_name":"StripeBank","stripe_account_id":"fca_1NxpBPKZLTSiovpVvp3l9fyY","subcategory":"checking","status":"active"}]

    return arr.map(a => ({
      label: a.display_name || '',
      value: a.stripe_client_id || '',
    }));
  };

  const delayedBlurCall = () => {
    setTimeout(() => {
      setSearchBoxFocused(false);
    }, 1000);
  };

  const validForm = () => {
    if (!titleValue) {
      setErrorMessage('Please enter a title');
      return false;
    }

    if (!roadValue) {
      setErrorMessage('Please enter a road & street info');
      return false;
    }

    if (!cityValue) {
      setErrorMessage('Please enter a city');
      return false;
    }

    if (!zipcodeValue) {
      setErrorMessage('Please enter a zipcode');
      return false;
    }

    if (!countryValue) {
      setErrorMessage('Please enter a country');
      return false;
    }

    console.log(addressLocation);
    if (!addressLocation?.latitude || !addressLocation?.longitude) {
      setErrorMessage('Please select a location from the map');
      return false;
    }

    return true;
  };

  const clearForm = () => {
    setHouseNumberValue('');
    setRoadValue('');
    setStateValue('');
    setCityValue('');
    setCountryValue('');
    setZipcodeValue('');
  };

  const updateFormFields = addressInfo => {
    // console.log('updateFormFields Function', addressInfo)

    clearForm();

    let houseNumber = '';
    let road = '';
    let state = '';
    let city = '';
    let country = '';
    let zipCode = '';

    if (addressInfo) {
      for (let i = 0; i < addressInfo?.length; i++) {
        const address = addressInfo[i];

        if (address.types.includes('street_number'))
          houseNumber = address.long_name;

        if (address.types.includes('route')) road = address.long_name;

        if (address.types.includes('administrative_area_level_1'))
          state = address.long_name;

        if (address.types.includes('locality')) city = address.long_name;

        if (address.types.includes('country')) country = address.long_name;

        if (address.types.includes('postal_code')) zipCode = address.long_name;
      }

      // console.log(houseNumber, road, state,city,country,zipCode )
      setHouseNumberValue(houseNumber);
      setRoadValue(road);
      setStateValue(state);
      setCityValue(city);
      setCountryValue(country);
      setZipcodeValue(zipCode);
    }
  };
  const xanoServiceAddAddressPOST = XanoServiceApi.useAddAddressPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
      trackScreen(segment, 'Add Address');
      if (props.route?.params?.isFirstAddress ?? defaultProps.isFirstAddress) {
        setTitleValue('Home');
      } else {
      }
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);
  const mapViewVrSRbRG1Ref = React.useRef();

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.CustomGray },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['Header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['Header'].style,
          dimensions.width
        )}
      >
        {/* AbsoluteTitle */}
        <View
          {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
            dimensions.width
          )}
        >
          <Image
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            resizeMode={'contain'}
            source={imageSource(Images['MailboxxGreenLogo'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { height: 30, width: 120 }
              ),
              dimensions.width
            )}
          />
        </View>
        {/* BackButton */}
        <>
          {!!(
            props.route?.params?.isOnboarding ?? defaultProps.isOnboarding
          ) ? null : (
            <Pressable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              {...GlobalStyles.PressableStyles(theme)['BackButton'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.PressableStyles(theme)['BackButton'].style,
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App['Custom Color_9']}
                  name={'Ionicons/chevron-back'}
                  size={28}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { color: theme.colors.branding.primary, fontSize: 18 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Back'}
                </Text>
              </View>
            </Pressable>
          )}
        </>
      </View>
      {/* Body */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
            backgroundColor: palettes.App.White,
            flex: 1,
            maxWidth: 1024,
            width: { minWidth: Breakpoints.Laptop, value: '100%' },
          },
          dimensions.width
        )}
      >
        {/* Container */}
        <SimpleStyleKeyboardAwareScrollView
          enableResetScrollToCoords={false}
          keyboardShouldPersistTaps={'never'}
          showsVerticalScrollIndicator={true}
          viewIsInsideTabBar={false}
          enableAutomaticScroll={true}
          enableOnAndroid={true}
          extraHeight={50}
          extraScrollHeight={200}
          style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
        >
          {/* SearchAddressView */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row', padding: 20 },
              dimensions.width
            )}
          >
            {/* SearchInputView */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, justifyContent: 'center' },
                dimensions.width
              )}
            >
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputValue => {
                  try {
                    setSearchDisplayValue(newTextInputValue);
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                }}
                onChangeTextDelayed={newTextInputValue => {
                  try {
                    setSearchValue(newTextInputValue);
                    if (searchDisplayValue) {
                      setSearchBoxFocused(true);
                    }
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                }}
                onFocus={() => {
                  try {
                    if (searchDisplayValue) {
                      setSearchBoxFocused(true);
                    }
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                placeholder={'Search your address'}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                    { marginTop: 0, paddingRight: 35 }
                  ),
                  dimensions.width
                )}
                value={searchDisplayValue}
              />
              <>
                {!searchDisplayValue ? null : (
                  <IconButton
                    onPress={() => {
                      try {
                        setSearchValue('');
                        setSearchDisplayValue('');
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                    size={32}
                    color={theme.colors.text.medium}
                    icon={'Ionicons/close-circle-outline'}
                    style={StyleSheet.applyWidth(
                      { marginTop: 4, position: 'absolute', right: 8 },
                      dimensions.width
                    )}
                  />
                )}
              </>
            </View>

            <View
              style={StyleSheet.applyWidth({ marginLeft: 8 }, dimensions.width)}
            >
              <IconButton
                onPress={() => {
                  const handler = async () => {
                    try {
                      const myLoction = await getLocationUtil({
                        accuracy: 'Highest',
                      });

                      mapViewVrSRbRG1Ref.current.animateToLocation({
                        latitude: myLoction?.latitude,
                        longitude: myLoction?.longitude,
                        zoom: 0,
                      });
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  };
                  handler();
                }}
                size={32}
                icon={'MaterialCommunityIcons/crosshairs-gps'}
              />
            </View>
          </View>
          {/* MapContainerView */}
          <View
            style={StyleSheet.applyWidth({ height: 350 }, dimensions.width)}
          >
            <MapView
              apiKey={'AIzaSyCR_GOL9ujGH5RYmiZzwvNxwEYVzvICEfg'}
              autoClusterMarkers={false}
              autoClusterMarkersDistanceMeters={10000}
              customMapStyle={'Lorem ipsum dolor sit amet'}
              latitude={37.40241}
              loadingEnabled={true}
              longitude={-122.12125}
              moveOnMarkerPress={true}
              onRegionChange={newRegion => {
                const handler = async () => {
                  try {
                    /* hidden 'Log to Console' action */
                    const newRegionDeclared = newRegion;
                    if (isInitialLoad) {
                    } else {
                      setIsUpdatingAddress(true);
                      const addressResult = (
                        await GoogleGeocodingApi.getAddressGET(Constants, {
                          lat: newRegionDeclared?.latitude,
                          lng: newRegionDeclared?.longitude,
                        })
                      )?.json;
                      setIsUpdatingAddress(false);
                      setAddressLocation(newRegionDeclared);
                      updateFormFields(
                        (addressResult?.results && (addressResult?.results)[0])
                          ?.address_components
                      );
                    }

                    setIsInitialLoad(false);
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                };
                handler();
              }}
              rotateEnabled={true}
              scrollEnabled={true}
              showsCompass={false}
              showsPointsOfInterest={true}
              showsUserLocation={false}
              zoom={8}
              zoomEnabled={true}
              {...GlobalStyles.MapViewStyles(theme)['Map View'].props}
              provider={'google'}
              ref={mapViewVrSRbRG1Ref}
              style={StyleSheet.applyWidth(
                GlobalStyles.MapViewStyles(theme)['Map View'].style,
                dimensions.width
              )}
            />
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['Pngaaacom5421128'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    left: getX(dimensions.width, 40),
                    position: 'absolute',
                    top: getX(350, 50),
                    width: 40,
                  }
                ),
                dimensions.width
              )}
            />
            {/* SearchResults */}
            <>
              {!searchBoxFocused ? null : (
                <Surface
                  elevation={0}
                  {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                      {
                        borderRadius: 12,
                        left: 20,
                        maxHeight: 350,
                        maxWidth: 980,
                        minHeight: 0,
                        position: 'absolute',
                        top: -20,
                        width: subtractPadding(dimensions.width, 20),
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <GoogleGeocodingApi.FetchAutocompleteGET
                    address={searchValue}
                  >
                    {({ loading, error, data, refetchAutocomplete }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <ActivityIndicator
                              animating={true}
                              hidesWhenStopped={true}
                              size={'small'}
                              {...GlobalStyles.ActivityIndicatorStyles(theme)[
                                'Activity Indicator'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ActivityIndicatorStyles(theme)[
                                    'Activity Indicator'
                                  ].style,
                                  {
                                    marginBottom: 15,
                                    marginLeft: 15,
                                    marginRight: 15,
                                    marginTop: 15,
                                  }
                                ),
                                dimensions.width
                              )}
                            />
                          </View>
                        );
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <SimpleStyleFlatList
                          data={fetchData?.predictions}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={'VrzlKVQN'}
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <Pressable
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      setSearchBoxFocused(false);
                                      const placeData = (
                                        await GoogleGeocodingApi.getPlaceDetailsGET(
                                          Constants,
                                          { placeId: listData?.place_id }
                                        )
                                      )?.json;
                                      /* hidden 'Log to Console' action */

                                      mapViewVrSRbRG1Ref.current.animateToLocation(
                                        {
                                          latitude:
                                            placeData?.geometry?.location?.lat,
                                          longitude:
                                            placeData?.geometry?.location?.lng,
                                          zoom: 18,
                                        }
                                      );
                                    } catch (err) {
                                      Sentry.captureException(err);
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      borderBottomWidth: 1,
                                      borderColor: palettes.App.CustomGray,
                                      flexDirection: 'row',
                                      margin: 8,
                                      marginTop: 0,
                                      padding: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Icon
                                    size={24}
                                    color={theme.colors.branding.primary}
                                    name={'Entypo/address'}
                                    style={StyleSheet.applyWidth(
                                      { marginRight: 12 },
                                      dimensions.width
                                    )}
                                  />
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    adjustsFontSizeToFit={false}
                                    ellipsizeMode={'tail'}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        { flex: 1 }
                                      ),
                                      dimensions.width
                                    )}
                                    textBreakStrategy={'highQuality'}
                                  >
                                    {listData?.description}
                                  </Text>
                                </View>
                              </Pressable>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                        />
                      );
                    }}
                  </GoogleGeocodingApi.FetchAutocompleteGET>
                </Surface>
              )}
            </>
          </View>

          <SimpleStyleScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            style={StyleSheet.applyWidth(
              { height: 200, margin: 10, paddingLeft: 10, paddingRight: 10 },
              dimensions.width
            )}
          >
            {/* DefaultAddressNames */}
            <>
              {!titleBoxFocued ? null : (
                <Surface
                  {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                  elevation={1}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                      {
                        borderBottomLeftRadius: 12,
                        borderBottomRightRadius: 12,
                        borderColor: palettes.Brand['Light Inverse'],
                        borderWidth: 1,
                        flex: 1,
                        left: 10,
                        marginLeft: 1,
                        marginRight: 1,
                        maxHeight: 350,
                        maxWidth: 980,
                        minHeight: 0,
                        position: 'absolute',
                        top: 38,
                        width: subtractPadding(dimensions.width, 21),
                        zIndex: 99,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App.White,
                        borderRadius: 50,
                        position: 'absolute',
                        right: -12,
                        top: -12,
                      },
                      dimensions.width
                    )}
                  >
                    <IconButton
                      onPress={() => {
                        try {
                          setTitleBoxFocued(false);
                        } catch (err) {
                          Sentry.captureException(err);
                          console.error(err);
                        }
                      }}
                      icon={'AntDesign/closecircleo'}
                      size={25}
                    />
                  </View>
                  <SimpleStyleFlatList
                    data={
                      Constants['USER_DATA']?.role === 'business'
                        ? defaultBusinessAddressTitles
                        : defaultAddressTitles
                    }
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ??
                      listData?.uuid ??
                      index?.toString() ??
                      JSON.stringify(listData)
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'SGslYB8P'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <Pressable
                          onPress={() => {
                            try {
                              setTitleBoxFocued(false);
                              setTitleValue(listData);
                            } catch (err) {
                              Sentry.captureException(err);
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderBottomWidth: [
                                  { minWidth: Breakpoints.Mobile, value: 1 },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value:
                                      index < defaultAddressTitles?.length - 1
                                        ? 1
                                        : 0,
                                  },
                                ],
                                borderColor: palettes.App.CustomGray,
                                flexDirection: 'row',
                                margin: 8,
                                marginTop: 0,
                                padding: 8,
                              },
                              dimensions.width
                            )}
                          >
                            <Icon
                              size={24}
                              color={theme.colors.branding.primary}
                              name={
                                listData === 'Home'
                                  ? 'Ionicons/home-outline'
                                  : listData === 'Work'
                                  ? 'Ionicons/business-outline'
                                  : listData === 'other'
                                  ? 'Ionicons/cafe-outline'
                                  : undefined
                              }
                              style={StyleSheet.applyWidth(
                                { marginRight: 12 },
                                dimensions.width
                              )}
                            />
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              adjustsFontSizeToFit={false}
                              ellipsizeMode={'tail'}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  { flex: 1 }
                                ),
                                dimensions.width
                              )}
                              textBreakStrategy={'highQuality'}
                            >
                              {listData}
                            </Text>
                          </View>
                        </Pressable>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                  />
                </Surface>
              )}
            </>
            {/* TitleInput */}
            <TextInput
              changeTextDelay={500}
              onChangeText={newTitleInputValue => {
                try {
                  setTitleValue(newTitleInputValue);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              onFocus={() => {
                try {
                  setTitleBoxFocued(true);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              autoCapitalize={'words'}
              autoCorrect={false}
              editable={
                !(
                  props.route?.params?.isFirstAddress ??
                  defaultProps.isFirstAddress
                )
              }
              placeholder={'Enter a title for this address'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  { marginBottom: 10, marginTop: 0 }
                ),
                dimensions.width
              )}
              value={titleValue}
            />
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row' },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { flex: 0.3, marginRight: 10 },
                  dimensions.width
                )}
              >
                {/* HouseNumberInput */}
                <TextInput
                  changeTextDelay={500}
                  onChangeText={newHouseNumberInputValue => {
                    try {
                      setHouseNumberValue(newHouseNumberInputValue);
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                  autoCapitalize={'words'}
                  autoCorrect={false}
                  keyboardType={'numeric'}
                  placeholder={'House #'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                      { marginBottom: 10, marginTop: 0 }
                    ),
                    dimensions.width
                  )}
                  value={houseNumberValue}
                />
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth({ flex: 0.7 }, dimensions.width)}
              >
                {/* RoadInput */}
                <TextInput
                  changeTextDelay={500}
                  onChangeText={newRoadInputValue => {
                    try {
                      setRoadValue(newRoadInputValue);
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                  autoCapitalize={'words'}
                  autoCorrect={false}
                  placeholder={'Road'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                      { marginBottom: 10, marginTop: 0 }
                    ),
                    dimensions.width
                  )}
                  value={roadValue}
                />
              </View>
            </View>

            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row' },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { flex: 0.5, marginRight: 10 },
                  dimensions.width
                )}
              >
                {/* ZipInput */}
                <TextInput
                  changeTextDelay={500}
                  onChangeText={newZipInputValue => {
                    try {
                      setZipcodeValue(newZipInputValue);
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                  autoCapitalize={'words'}
                  autoCorrect={false}
                  keyboardType={'numeric'}
                  placeholder={'Zip Code'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                      { marginBottom: 10, marginTop: 0 }
                    ),
                    dimensions.width
                  )}
                  textContentType={'postalCode'}
                  value={zipcodeValue}
                />
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth({ flex: 0.5 }, dimensions.width)}
              >
                {/* CityInput */}
                <TextInput
                  changeTextDelay={500}
                  onChangeText={newCityInputValue => {
                    try {
                      setCityValue(newCityInputValue);
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                  autoCapitalize={'words'}
                  autoCorrect={false}
                  placeholder={'City'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                      { marginBottom: 10, marginTop: 0 }
                    ),
                    dimensions.width
                  )}
                  textContentType={'addressCity'}
                  value={cityValue}
                />
              </View>
            </View>

            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row' },
                dimensions.width
              )}
            >
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 0.5, marginRight: 10 },
                  dimensions.width
                )}
              >
                {/* StateInput */}
                <TextInput
                  changeTextDelay={500}
                  onChangeText={newStateInputValue => {
                    try {
                      setStateValue(newStateInputValue);
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                  autoCapitalize={'words'}
                  autoCorrect={false}
                  placeholder={'State'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                      { marginTop: 0 }
                    ),
                    dimensions.width
                  )}
                  textContentType={'addressState'}
                  value={stateValue}
                />
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth({ flex: 0.5 }, dimensions.width)}
              >
                {/* CountryInput */}
                <TextInput
                  changeTextDelay={500}
                  onChangeText={newCountryInputValue => {
                    try {
                      setCountryValue(newCountryInputValue);
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                  autoCapitalize={'words'}
                  autoCorrect={false}
                  placeholder={'Country'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                      { marginTop: 0 }
                    ),
                    dimensions.width
                  )}
                  textContentType={'countryName'}
                  value={countryValue}
                />
              </View>
            </View>
            {/* View 2 */}
            <>
              {!isUpdatingAddress ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: palettes.App.ViewBG,
                      borderRadius: 12,
                      height: '100%',
                      justifyContent: 'center',
                      left: 0,
                      marginLeft: 10,
                      position: 'absolute',
                      width: subtractPadding(dimensions.width, 20),
                    },
                    dimensions.width
                  )}
                >
                  <ActivityIndicator
                    animating={true}
                    hidesWhenStopped={true}
                    {...GlobalStyles.ActivityIndicatorStyles(theme)[
                      'Activity Indicator'
                    ].props}
                    size={'large'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ActivityIndicatorStyles(theme)[
                        'Activity Indicator'
                      ].style,
                      dimensions.width
                    )}
                  />
                </View>
              )}
            </>
          </SimpleStyleScrollView>
          {/* ErrorText */}
          <>
            {!errorMessage ? null : (
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: theme.colors.background.danger,
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 13,
                      marginLeft: 20,
                      marginRight: 20,
                    }
                  ),
                  dimensions.width
                )}
              >
                {errorMessage}
              </Text>
            )}
          </>
          {/* AddAddressButton */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  if (!validForm()) {
                    return;
                  }
                  setIsSavingAddress(true);
                  const addAddressResult = (
                    await xanoServiceAddAddressPOST.mutateAsync({
                      city: cityValue,
                      country: countryValue,
                      house_number: houseNumberValue,
                      lat: addressLocation?.latitude,
                      lng: addressLocation?.longitude,
                      road: roadValue,
                      state: stateValue,
                      title: titleValue,
                      zip: zipcodeValue,
                    })
                  )?.json;
                  setIsSavingAddress(false);
                  setErrorMessage(addAddressResult?.message);
                  clearForm();
                  if (
                    props.route?.params?.isOnboarding ??
                    defaultProps.isOnboarding
                  ) {
                    trackAction(
                      Variables,
                      segment,
                      'Added New Address',
                      titleValue
                    );
                    /* hidden 'Navigate' action */
                    navigation.navigate('AuthContactsImportScreen');
                  } else {
                    navigation.goBack();
                  }
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              };
              handler();
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            disabled={isSavingAddress}
            loading={isSavingAddress}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                { margin: 20, marginTop: 10 }
              ),
              dimensions.width
            )}
            title={'Add This Address'}
          />
        </SimpleStyleKeyboardAwareScrollView>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AddAddressScreen);
