import React from 'react';
import {
  Button,
  Checkbox,
  Icon,
  IconButton,
  Link,
  Picker,
  Pressable,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { ActivityIndicator, Image, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as PhonePicker from '../custom-files/PhonePicker';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackAction from '../global-functions/trackAction';
import trackIdentity from '../global-functions/trackIdentity';
import trackScreen from '../global-functions/trackScreen';
import transformBusinessCategories from '../global-functions/transformBusinessCategories';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';

const AuthSignUpBusinessScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [accountImageValue, setAccountImageValue] = React.useState('');
  const [agreeValue, setAgreeValue] = React.useState(false);
  const [allowEmailsValue, setAllowEmailsValue] = React.useState(false);
  const [businessCategoryValue, setBusinessCategoryValue] = React.useState('');
  const [businessEinNumberValue, setBusinessEinNumberValue] =
    React.useState('');
  const [businessNameValue, setBusinessNameValue] = React.useState('');
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] =
    React.useState('');
  const [confirmPasswordValue, setConfirmPasswordValue] = React.useState('');
  const [dobValue, setDobValue] = React.useState(new Date());
  const [emailValue, setEmailValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [firstNameValue, setFirstNameValue] = React.useState('');
  const [genderValue, setGenderValue] = React.useState('');
  const [headerImageValue, setHeaderImageValue] = React.useState('');
  const [lastNameValue, setLastNameValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [numberValid, setNumberValid] = React.useState(false);
  const [passwordErrorText, setPasswordErrorText] = React.useState('');
  const [passwordValue, setPasswordValue] = React.useState('');
  const [phoneNumberValue, setPhoneNumberValue] = React.useState('');
  const [secondaryEmailValue, setSecondaryEmailValue] = React.useState('');
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const validatePassword = pass => {
    const passwordExpr = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d\W_]{8,}$/;

    if (!pass) {
      setPasswordErrorText('');
      return false;
    }

    if (!passwordExpr.test(pass)) {
      setPasswordErrorText(
        'Password must be at least 8 characters, contain at least 1 small , 1 capital letter and 1 number '
      );
      return false;
    }

    setPasswordErrorText('');
    return true;
  };

  const validateConfirmPassword = (pass, confirmPass) => {
    const passwordExpr = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d\W_]{8,}$/;

    if (!confirmPass) {
      setConfirmPasswordErrorText('');
      return false;
    }

    if (pass !== confirmPass) {
      setConfirmPasswordErrorText('Password and confirm password must be same');
      return false;
    }

    setConfirmPasswordErrorText('');
    return true;
  };

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneNumberRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    const passwordExpr = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d\W_]{8,}$/;

    if (accountImageValue.length < 1) {
      setErrorMessage('You must select a company logo before you continue');
      return false;
    }

    if (businessNameValue.length < 1) {
      setErrorMessage('Please enter business name');
      return false;
    }

    // if(businessEinNumberValue.length<1) {
    //   setErrorMessage('Please enter business EIN number');
    //   return false;
    // }

    if (firstNameValue.length < 1) {
      setErrorMessage('Please enter first name');
      return false;
    }

    if (lastNameValue.length < 1) {
      setErrorMessage('Please enter last name');
      return false;
    }
    if (businessCategoryValue.length < 1) {
      setErrorMessage('Please select business category');
      return false;
    }

    if (phoneNumberValue.length < 1) {
      setErrorMessage('Please enter phone number');
      return false;
    }

    if (!phoneNumberRegex.test(phoneNumberValue)) {
      setErrorMessage('Please enter valid phone number');
      return false;
    }
    if (emailValue.length < 1) {
      setErrorMessage('Please enter email');
      return false;
    }

    if (!emailRegex.test(emailValue.trim())) {
      setErrorMessage('Please enter valid email');
      return false;
    }

    // if (
    //   secondaryEmailValue.length > 0 &&
    //   !emailRegex.test(secondaryEmailValue)
    // ) {
    //   setErrorMessage('Please enter valid secondary email');
    //   return false;
    // }

    if (passwordValue.length < 1) {
      setErrorMessage('Please enter password');
      return false;
    }

    if (!passwordExpr.test(passwordValue)) {
      setErrorMessage(
        'Password must be at least 8 characters, contain at least 1 small , 1 capital letter and 1 number '
      );
      return false;
    }

    if (confirmPasswordValue.length < 1) {
      setErrorMessage('Please enter confirm password');
      return false;
    }

    if (passwordValue !== confirmPasswordValue) {
      setErrorMessage('Password and confirm password must be same');
      return false;
    }

    setErrorMessage('');
    return true;
  };
  const xanoServiceBusinessSignupPOST = XanoServiceApi.useBusinessSignupPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      trackScreen(segment, 'SignUp Business');
      if (!isLoggedIn(Variables)) {
        return;
      }
      if (Constants['USER_DATA']?.role === 'admin') {
        navigation.navigate('AdminDashboardScreen');
      } else {
        if (Constants['USER_DATA']?.role?.business) {
          navigation.navigate('MyPromotionsScreen');
        } else {
          navigation.navigate('InboxScreen');
        }
      }
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.White, paddingBottom: 10 },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['Header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['Header'].style,
          dimensions.width
        )}
      >
        {/* AbsoluteTitle */}
        <View
          {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
            dimensions.width
          )}
        >
          <Image
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            resizeMode={'contain'}
            source={imageSource(Images['MailboxxGreenLogo'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { height: 30, width: 120 }
              ),
              dimensions.width
            )}
          />
        </View>
        {/* BackButton */}
        <Pressable
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          }}
          {...GlobalStyles.PressableStyles(theme)['BackButton'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['BackButton'].style,
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              },
              dimensions.width
            )}
          >
            <Icon
              color={palettes.App['Custom Color_9']}
              name={'Ionicons/chevron-back'}
              size={28}
            />
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['HeaderTitleText'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['HeaderTitleText'].style,
                  { color: palettes.App.customGreen }
                ),
                dimensions.width
              )}
            >
              {'Back'}
            </Text>
          </View>
        </Pressable>
      </View>
      {/* Main */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
            flex: [
              { minWidth: Breakpoints.Desktop, value: 1 },
              { minWidth: Breakpoints.Mobile, value: 1 },
            ],
            justifyContent: { minWidth: Breakpoints.Desktop, value: 'center' },
            maxWidth: 1024,
          },
          dimensions.width
        )}
      >
        <SimpleStyleKeyboardAwareScrollView
          showsVerticalScrollIndicator={true}
          viewIsInsideTabBar={false}
          enableAutomaticScroll={true}
          enableOnAndroid={true}
          enableResetScrollToCoords={true}
          extraScrollHeight={50}
          keyboardShouldPersistTaps={'always'}
          style={StyleSheet.applyWidth(
            { paddingBottom: 40, paddingLeft: 20, paddingRight: 20 },
            dimensions.width
          )}
        >
          {/* Label */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                fontSize: 32,
                marginTop: 30,
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {'Business Sign Up'}
          </Text>
          {/* HeaderImage */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            {/* InnerHeaderImage */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              <Pressable
                onPress={() => {
                  const handler = async () => {
                    try {
                      const heroImage = await openImagePickerUtil({
                        mediaTypes: 'All',
                        allowsEditing: false,
                        quality: 0.2,
                        allowsMultipleSelection: false,
                        permissionErrorMessage:
                          'Sorry, we need media library permissions to make this work.',
                        showAlertOnPermissionError: true,
                        outputBase64: true,
                      });

                      if (heroImage) {
                        setHeaderImageValue(heroImage);
                      }
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  };
                  handler();
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginTop: 10 },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: palettes.App.CustomGray,
                        borderRadius: 8,
                        flexDirection: 'row',
                        height: 70,
                        justifyContent: 'center',
                        width: 210,
                      },
                      dimensions.width
                    )}
                  >
                    <>
                      {!headerImageValue ? null : (
                        <Image
                          {...GlobalStyles.ImageStyles(theme)['Image'].props}
                          resizeMode={'center'}
                          source={imageSource(`${headerImageValue}`)}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'].style,
                              { borderRadius: 8, height: 70, width: 210 }
                            ),
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    <>
                      {headerImageValue ? null : (
                        <Icon size={24} name={'AntDesign/camerao'} />
                      )}
                    </>
                  </View>
                </View>
              </Pressable>

              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { fontSize: 18, marginTop: 10, textAlign: 'center' }
                  ),
                  dimensions.width
                )}
              >
                {'Hero Image'}
              </Text>
              {/* ClearButton */}
              <>
                {!headerImageValue ? null : (
                  <IconButton
                    onPress={() => {
                      try {
                        setHeaderImageValue('');
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                    icon={'AntDesign/closecircleo'}
                    size={28}
                    style={StyleSheet.applyWidth(
                      { position: 'absolute', right: -16, top: 0 },
                      dimensions.width
                    )}
                  />
                )}
              </>
            </View>
          </View>
          {/* Logo */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            {/* InnerLogo */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              <Pressable
                onPress={() => {
                  const handler = async () => {
                    try {
                      const logo = await openImagePickerUtil({
                        mediaTypes: 'All',
                        allowsEditing: false,
                        quality: 0.2,
                        allowsMultipleSelection: false,
                        permissionErrorMessage:
                          'Sorry, we need media library permissions to make this work.',
                        showAlertOnPermissionError: true,
                        outputBase64: true,
                      });

                      if (logo) {
                        setAccountImageValue(logo);
                      }
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  { borderColor: palettes.App['Custom #aaa7a3'] },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginTop: 10 },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: palettes.App.CustomGray,
                        borderRadius: 8,
                        flexDirection: 'row',
                        height: 70,
                        justifyContent: 'center',
                        width: 70,
                      },
                      dimensions.width
                    )}
                  >
                    <>
                      {!accountImageValue ? null : (
                        <Image
                          {...GlobalStyles.ImageStyles(theme)['Image'].props}
                          resizeMode={'center'}
                          source={imageSource(`${accountImageValue}`)}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'].style,
                              { borderRadius: 8, height: 70, width: 70 }
                            ),
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    <>
                      {accountImageValue ? null : (
                        <Icon size={24} name={'AntDesign/camerao'} />
                      )}
                    </>
                  </View>
                </View>
              </Pressable>

              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { fontSize: 18, marginTop: 10, textAlign: 'center' }
                  ),
                  dimensions.width
                )}
              >
                {'Logo'}
              </Text>
              {/* ClearButton */}
              <>
                {!accountImageValue ? null : (
                  <IconButton
                    onPress={() => {
                      try {
                        setAccountImageValue('');
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                    icon={'AntDesign/closecircleo'}
                    size={28}
                    style={StyleSheet.applyWidth(
                      { position: 'absolute', right: -16, top: 0 },
                      dimensions.width
                    )}
                  />
                )}
              </>
            </View>
          </View>
          {/* BusinessnameInput */}
          <TextInput
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newBusinessnameInputValue => {
              try {
                setBusinessNameValue(newBusinessnameInputValue);
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            }}
            webShowOutline={true}
            {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
            autoCapitalize={'words'}
            autoFocus={true}
            placeholder={'Business Name'}
            placeholderTextColor={theme.colors.text.light}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                { marginTop: 40 }
              ),
              dimensions.width
            )}
            value={businessNameValue}
          />
          {/* FirstnameInput */}
          <TextInput
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newFirstnameInputValue => {
              try {
                setFirstNameValue(newFirstnameInputValue);
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            }}
            webShowOutline={true}
            {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
            autoCapitalize={'words'}
            placeholder={'Representative First Name'}
            placeholderTextColor={theme.colors.text.light}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                { marginTop: 20 }
              ),
              dimensions.width
            )}
            textContentType={'name'}
            value={firstNameValue}
          />
          {/* LastnameInput */}
          <TextInput
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newLastnameInputValue => {
              try {
                setLastNameValue(newLastnameInputValue);
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            }}
            webShowOutline={true}
            {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
            autoCapitalize={'words'}
            placeholder={'Representative Last Name'}
            placeholderTextColor={theme.colors.text.light}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                { marginTop: 20 }
              ),
              dimensions.width
            )}
            textContentType={'familyName'}
            value={lastNameValue}
          />
          <XanoServiceApi.FetchGetBussinessCategoriesGET excludeEmpty={false}>
            {({ loading, error, data, refetchGetBussinessCategories }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <View>
                  {/* BusinessCategoryPicker */}
                  <Picker
                    autoDismissKeyboard={true}
                    dropDownBackgroundColor={theme.colors.background.brand}
                    dropDownBorderColor={theme.colors.border.brand}
                    dropDownBorderRadius={8}
                    dropDownBorderWidth={1}
                    dropDownTextColor={theme.colors.text.strong}
                    iconSize={24}
                    leftIconMode={'inset'}
                    mode={'native'}
                    onValueChange={newBusinessCategoryPickerValue => {
                      try {
                        setBusinessCategoryValue(
                          newBusinessCategoryPickerValue
                        );
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                    selectedIconColor={theme.colors.text.strong}
                    selectedIconName={'Feather/check'}
                    selectedIconSize={20}
                    options={transformBusinessCategories(fetchData)}
                    placeholder={'Business Category'}
                    placeholderTextColor={theme.colors.text.light}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App.CustomGray,
                        borderRadius: 8,
                        borderWidth: 0,
                        fontFamily: 'Poppins_200ExtraLight',
                        fontSize: 13,
                        padding: 8,
                      },
                      dimensions.width
                    )}
                    type={'underline'}
                    value={businessCategoryValue}
                  />
                </View>
              );
            }}
          </XanoServiceApi.FetchGetBussinessCategoriesGET>
          <Utils.CustomCodeErrorBoundary>
            <PhonePicker.CountryPickerComponent
              setNumberValid={setNumberValid}
              setNumber={setPhoneNumberValue}
              theme={props.theme}
            />
          </Utils.CustomCodeErrorBoundary>
          {/* EmailInput */}
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newEmailInputValue => {
              try {
                setEmailValue(newEmailInputValue);
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            }}
            webShowOutline={true}
            {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
            keyboardType={'email-address'}
            placeholder={'Email'}
            placeholderTextColor={theme.colors.text.light}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                { marginTop: 20 }
              ),
              dimensions.width
            )}
            textContentType={'emailAddress'}
            value={emailValue}
          />
          {/* PasswordInput */}
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newPasswordInputValue => {
              try {
                setPasswordValue(newPasswordInputValue);
                validatePassword(newPasswordInputValue);
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            }}
            webShowOutline={true}
            {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
            keyboardType={'default'}
            placeholder={'Password'}
            placeholderTextColor={theme.colors.text.light}
            secureTextEntry={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                { marginTop: 20 }
              ),
              dimensions.width
            )}
            value={passwordValue}
          />
          {/* passwordErrorText */}
          <>
            {!passwordErrorText ? null : (
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: theme.colors.background.danger,
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {passwordErrorText}
              </Text>
            )}
          </>
          {/* ConfirmPasswordInput */}
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newConfirmPasswordInputValue => {
              try {
                setConfirmPasswordValue(newConfirmPasswordInputValue);
                validateConfirmPassword(
                  passwordValue,
                  newConfirmPasswordInputValue
                );
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            }}
            webShowOutline={true}
            {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
            keyboardType={'default'}
            placeholder={'Confirm Password'}
            placeholderTextColor={theme.colors.text.light}
            secureTextEntry={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                { marginTop: 20 }
              ),
              dimensions.width
            )}
            value={confirmPasswordValue}
          />
          {/* confirmPasswordErrorText */}
          <>
            {!confirmPasswordErrorText ? null : (
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: theme.colors.background.danger,
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {confirmPasswordErrorText}
              </Text>
            )}
          </>
          {/* errorView */}
          <>
            {!errorMessage ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors.border.brand,
                    borderRadius: 13,
                    marginTop: 20,
                    padding: 11,
                  },
                  dimensions.width
                )}
              >
                {/* errorText */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.background.danger,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {errorMessage}
                </Text>
              </View>
            )}
          </>
          {/* Terms and Conditions Text */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'space-between',
                alignItems: [
                  { minWidth: Breakpoints.Mobile, value: 'flex-start' },
                  { minWidth: Breakpoints.Desktop, value: 'center' },
                ],
                alignSelf: 'auto',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginTop: 20,
              },
              dimensions.width
            )}
          >
            <Checkbox
              onPress={newCheckboxValue => {
                const checkboxValue = newCheckboxValue;
                try {
                  setAgreeValue(newCheckboxValue);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              status={agreeValue}
            />
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: theme.colors.text.strong,
                    paddingLeft: { minWidth: Breakpoints.Desktop, value: 16 },
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'I agree to the '}
            </Text>

            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('TermsandConditionsScreen');
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { fontFamily: 'Poppins_300Light', marginLeft: 4 }
                  ),
                  dimensions.width
                )}
              >
                {'Terms & Conditions'}
              </Text>
            </Pressable>

            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { color: theme.colors.text.strong, marginLeft: 4 }
                ),
                dimensions.width
              )}
            >
              {'and'}
            </Text>
            {/* Pressable 2 */}
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('PrivacyPolicyScreen');
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: palettes.App.customGreen,
                      fontFamily: 'Poppins_300Light',
                      marginLeft: 4,
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Privacy Policy'}
              </Text>
            </Pressable>
          </View>
          {/* AllowEmails */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'space-between',
                alignItems: [
                  { minWidth: Breakpoints.Mobile, value: 'flex-start' },
                  { minWidth: Breakpoints.Desktop, value: 'center' },
                ],
                alignSelf: 'auto',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginTop: 20,
              },
              dimensions.width
            )}
          >
            <Checkbox
              onPress={newCheckboxValue => {
                try {
                  setAllowEmailsValue(newCheckboxValue);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              status={allowEmailsValue}
            />
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: theme.colors.text.strong,
                    paddingLeft: { minWidth: Breakpoints.Desktop, value: 16 },
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'I agree to receive emails from Mailboxx'}
            </Text>
          </View>
          {/* CreateAccountButton */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                console.log('CreateAccountButton ON_PRESS Start');
                let error = null;
                try {
                  console.log('Start ON_PRESS:0 CUSTOM_FUNCTION');
                  const isFormValid = validateForm();
                  console.log('Complete ON_PRESS:0 CUSTOM_FUNCTION', {
                    isFormValid,
                  });
                  console.log('Start ON_PRESS:1 CONDITIONAL_STOP');
                  if (!isFormValid) {
                    return console.log('Complete ON_PRESS:1 CONDITIONAL_STOP');
                  } else {
                    console.log(
                      'Skipped ON_PRESS:1 CONDITIONAL_STOP: condition not met'
                    );
                  }
                  console.log('Start ON_PRESS:2 SET_VARIABLE');
                  setLoading(true);
                  console.log('Complete ON_PRESS:2 SET_VARIABLE');
                  console.log('Start ON_PRESS:3 FETCH_REQUEST');
                  const businessSignupResult = (
                    await xanoServiceBusinessSignupPOST.mutateAsync({
                      account_image: accountImageValue,
                      allow_email: allowEmailsValue,
                      business_categories_id: businessCategoryValue,
                      business_ein: businessEinNumberValue,
                      business_name: businessNameValue,
                      email: emailValue,
                      first_name: firstNameValue,
                      header_image: headerImageValue,
                      last_name: lastNameValue,
                      password: passwordValue,
                      phone: phoneNumberValue,
                      secondary_email: secondaryEmailValue,
                    })
                  )?.json;
                  console.log('Complete ON_PRESS:3 FETCH_REQUEST', {
                    businessSignupResult,
                  });
                  console.log('Start ON_PRESS:4 SET_VARIABLE');
                  setLoading(false);
                  console.log('Complete ON_PRESS:4 SET_VARIABLE');
                  console.log('Start ON_PRESS:5 SET_VARIABLE');
                  setErrorMessage(businessSignupResult?.message);
                  console.log('Complete ON_PRESS:5 SET_VARIABLE');
                  console.log('Start ON_PRESS:6 SET_VARIABLE');
                  setGlobalVariableValue({
                    key: 'USER_DATA',
                    value: businessSignupResult?.user,
                  });
                  console.log('Complete ON_PRESS:6 SET_VARIABLE');
                  console.log('Start ON_PRESS:7 SET_VARIABLE');
                  setGlobalVariableValue({
                    key: 'AuthToken',
                    value: businessSignupResult?.authToken,
                  });
                  console.log('Complete ON_PRESS:7 SET_VARIABLE');
                  console.log('Start ON_PRESS:8 CUSTOM_FUNCTION');
                  trackIdentity(segment, emailValue);
                  console.log('Complete ON_PRESS:8 CUSTOM_FUNCTION');
                  console.log('Start ON_PRESS:9 CUSTOM_FUNCTION');
                  trackAction(
                    Variables,
                    segment,
                    'Sign Up Successful',
                    'Business'
                  );
                  console.log('Complete ON_PRESS:9 CUSTOM_FUNCTION');
                  console.log('Start ON_PRESS:10 IF');
                  if (Platform.OS === 'web') {
                    /* hidden 'Navigate' action */
                    if (businessSignupResult?.authToken) {
                      navigation.navigate('AddAddressScreen', {
                        isFirstAddress: true,
                        isOnboarding: true,
                      });
                    }
                  } else {
                    if (businessSignupResult?.authToken) {
                      navigation.navigate('AuthNotificationPermissionsScreen');
                    }
                  }
                  console.log('Complete ON_PRESS:10 IF');
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                  error = err.message ?? err;
                }
                console.log(
                  'CreateAccountButton ON_PRESS Complete',
                  error ? { error } : 'no error'
                );
              };
              handler();
            }}
            {...GlobalStyles.ButtonStyles(theme)['PrimaryButton'].props}
            disabled={loading || !agreeValue}
            loading={loading}
            style={StyleSheet.applyWidth(
              GlobalStyles.ButtonStyles(theme)['PrimaryButton'].style,
              dimensions.width
            )}
            title={'Create an Account'}
          />
          {/* LogInButton */}
          <Link
            accessible={true}
            onPress={() => {
              try {
                navigation.navigate('AuthLoginScreen');
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            }}
            selectable={false}
            {...GlobalStyles.LinkStyles(theme)['Link'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'].style, {
                alignSelf: 'center',
                fontSize: 18,
                marginTop: '30%',
              }),
              dimensions.width
            )}
            title={'Log In'}
          />
        </SimpleStyleKeyboardAwareScrollView>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AuthSignUpBusinessScreen);
