import { StackActions, CommonActions } from '@react-navigation/native';
import { Platform, Image as RNImage, Linking } from 'react-native';
import { showMessage } from 'react-native-flash-message';

const { Image } = Platform.select({
  ios: () => require('expo-image'),
  android: () => require('expo-image'),
  web: () => require('react-native'),
})();

export { StackActions, CommonActions, Image, RNImage, Linking, showMessage };
