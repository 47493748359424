import React from 'react';
import {
  Icon,
  Pressable,
  ScreenContainer,
  SwitchRow,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { Image, Platform, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoServiceApi from '../apis/XanoServiceApi.js';
import WideHeaderBlock from '../components/WideHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import getPushTokenUtil from '../utils/getPushToken';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const PermissionsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [emailPermissionsValue, setEmailPermissionsValue] = React.useState(
    Constants['USER_DATA']?.allow_email
  );
  const [notificationPermissionsValue, setNotificationPermissionsValue] =
    React.useState(Constants['USER_DATA']?.push_token ? true : false);
  const [switchValue, setSwitchValue] = React.useState(false);
  const updateAllowEmail = (user, allow_email) => {
    return {
      ...user,
      allow_email,
    };
  };

  const updatePushToken = (user, push_token) => {
    return {
      ...user,
      push_token,
    };
  };
  const xanoServiceToggleEmailPOST = XanoServiceApi.useToggleEmailPOST();
  const xanoServiceTogglePushTokenPOST =
    XanoServiceApi.useTogglePushTokenPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      trackScreen(segment, 'Permissions');
      if (!isLoggedIn(Variables)) {
        navigation.navigate('AuthLoginScreen');
      }
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: [
            { minWidth: Breakpoints.Mobile, value: palettes.App.White },
            { minWidth: Breakpoints.Desktop, value: palettes.App.CustomGray },
          ],
          height: '100%',
        },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <WideHeaderBlock currentScreen={'settings'} withGoBack={true} />
        )}
      </>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Header'].style,
              dimensions.width
            )}
          >
            {/* AbsoluteTitle */}
            <View
              {...GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['AbsoluteTitle'].style,
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={imageSource(Images['MailboxxGreenLogo'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 30, width: 120 }
                  ),
                  dimensions.width
                )}
              />
            </View>
            {/* BackButton */}
            <Pressable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              {...GlobalStyles.PressableStyles(theme)['BackButton'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.PressableStyles(theme)['BackButton'].style,
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={theme.colors.branding.primary}
                  name={'Ionicons/chevron-back'}
                  size={28}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { fontSize: 18 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Back'}
                </Text>
              </View>
            </Pressable>
          </View>
        )}
      </>
      {/* Body */}
      <View
        {...GlobalStyles.ViewStyles(theme)['ResponsiveBody'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['ResponsiveBody'].style,
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { gap: 12, padding: 20, paddingTop: 30 },
            dimensions.width
          )}
        >
          {/* Title */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['TitleText'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['TitleText'].style,
              dimensions.width
            )}
          >
            {'Permissions'}
          </Text>

          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: theme.colors.text.strong,
              }),
              dimensions.width
            )}
          >
            {'You can set your communication preferences from below\n'}
          </Text>
          {/* EmailToggle */}
          <SwitchRow
            onValueChange={newEmailToggleValue => {
              const handler = async () => {
                const switchValue = newEmailToggleValue;
                try {
                  setEmailPermissionsValue(newEmailToggleValue);
                  const toggleResult = (
                    await xanoServiceToggleEmailPOST.mutateAsync()
                  )?.json;
                  const newUser = updateAllowEmail(
                    Constants['USER_DATA'],
                    newEmailToggleValue
                  );
                  if (newUser) {
                    setGlobalVariableValue({
                      key: 'USER_DATA',
                      value: newUser,
                    });
                  }
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              };
              handler();
            }}
            {...GlobalStyles.SwitchRowStyles(theme)['Switch Row'].props}
            label={'Allow Emails'}
            style={StyleSheet.applyWidth(
              GlobalStyles.SwitchRowStyles(theme)['Switch Row'].style,
              dimensions.width
            )}
            value={emailPermissionsValue}
          />
          {/* NotificationsToggle */}
          <>
            {Platform.OS === 'web' ? null : (
              <SwitchRow
                onValueChange={newNotificationsToggleValue => {
                  const handler = async () => {
                    const switchValue = newNotificationsToggleValue;
                    try {
                      setNotificationPermissionsValue(
                        newNotificationsToggleValue
                      );
                      if (newNotificationsToggleValue) {
                        const pushToken = await getPushTokenUtil({
                          permissionErrorMessage:
                            'Sorry, we need notifications permissions to make this work.',
                          deviceErrorMessage:
                            'Must use physical device for Push Notifications.',
                          showAlertOnPermissionError: true,
                          showAlertOnDeviceError: true,
                        });

                        const toggleResult = (
                          await xanoServiceTogglePushTokenPOST.mutateAsync({
                            push_token: pushToken,
                          })
                        )?.json;
                        const newUser = updatePushToken(
                          Constants['USER_DATA'],
                          pushToken
                        );
                        setGlobalVariableValue({
                          key: 'USER_DATA',
                          value: newUser,
                        });
                      } else {
                        const toggleResult2 = (
                          await xanoServiceTogglePushTokenPOST.mutateAsync({
                            push_token: '',
                          })
                        )?.json;
                        const newUser2 = updatePushToken(
                          Constants['USER_DATA'],
                          ''
                        );
                        setGlobalVariableValue({
                          key: 'USER_DATA',
                          value: newUser2,
                        });
                      }
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  };
                  handler();
                }}
                {...GlobalStyles.SwitchRowStyles(theme)['Switch Row'].props}
                label={'Allow Notifications'}
                style={StyleSheet.applyWidth(
                  GlobalStyles.SwitchRowStyles(theme)['Switch Row'].style,
                  dimensions.width
                )}
                value={notificationPermissionsValue}
              />
            )}
          </>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(PermissionsScreen);
