/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking';
import { Platform } from 'react-native';

function renderLinkingPrefix() {
  try {
    return Linking.createURL('/');
  } catch (e) {
    return 'draftbit://';
  }
}

const prefix = renderLinkingPrefix();

const linking = {
  enabled: true,
  prefixes: [prefix], // Assuming 'prefix' is defined elsewhere
  config: {
    screens: {
      AddAddressScreen: 'add-address/:isOnboarding?',
      AddPromotionFormScreen: 'add-promotion/:promoType?/:for_explore?/:for_category?/:selectedAddress?',
      AddPromotionSelectTypeScreen: 'select-promotion-type/:isNearBy?',
      AddressesScreen: 'addresses',
      AdminAddressApprovalScreen: 'admin/approve-address',
      AdminBusinessCategoriesScreen: 'admin/business-categories',
      AdminDashboardScreen: 'admin/dashboard',
      AuthBankAccountsScreen: 'auth/bank-accounts',
      AuthDoNotCallScreen: 'auth/do-not-call',
      AuthForgetPasswordScreen: 'auth/forget-password',
      AuthForgetPasswordUpdateScreen: 'auth/update-password',
      AuthLoginScreen: 'auth/login',
      AuthNotificationPermissionsScreen: 'auth/notification-permissions',
      AuthSelectSignupTypeScreen: 'auth/select-signup-type',
      AuthSignUpBusinessScreen: 'auth/signup-business',
      AuthSignUpUserScreen: 'auth/signup-user',
      AuthStripeConnectScreen: 'auth/stripe-connect',
      AuthContactsImportScreen : 'auth/contacts-import',
      BalanceScreen: 'balance',
      BalanceUpdateScreen: 'balance/update',
      BankAccountsScreen: 'bank-accounts',
      BusinessProfileScreen: 'business-profile/:business_id?',
      BusinessFollowingScreen: "following",
      BusinessSearchScreen : "business-search",
      ChangePasswordScreen: 'change-password',
      ContactsImportScreen : 'contacts-import',
      CallbackScreen : 'stripe_callback/:connected_account_id',
      DeleteAccountScreen: {
        path: 'delete-account',
        parse: {
          name: (name) => name,
          email: (email) => email,
          password : (password) => password
        }
      },
      GetInContactScreen: 'contact',
      InboxScreen: 'inbox',
      MyPromotionsScreen: 'my-promotions',
      NotificationScreen: 'notifications',
      PreviewExplorePromotionScreen: 'preview-explore/:banner?/:promoDiscount?/:subTitle?/:title?/:mode?/:logo?/:content?',
      PreviewPromotionScreen: 'preview/:banner?/:promoDiscount?/:subTitle?/:title?/:mode?/:logo?/:content?',
      PrivacyPolicyScreen: 'privacy-policy',
      ProfileUpdateScreen: 'profile/update',
      PermissionsScreen: 'permissions',
      PromotionStatsScreen: 'promotion-stats/:promotion_id?',
      SavedScreen: 'saved',
      SettingsScreen: 'settings',
      TermsandConditionsScreen: 'terms-and-conditions',
      ViewPromotionScreen: 'promo/:PromotionId?/:hideBtn?',
      WelcomeScreen: 'welcome',
      UnsubscribeScreen: 'unsubscribe/:token?',
    },
  },
};


export default linking;
