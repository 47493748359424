export default {
  TickCircle: require('../assets/images/TickCircle.png'),
  HomeIcon: require('../assets/images/HomeIcon.png'),
  Bills: require('../assets/images/Bills.png'),
  Purchase: require('../assets/images/Purchase.png'),
  PermissionsNotification: require('../assets/images/PermissionsNotification.png'),
  Pngaaacom5421128: require('../assets/images/Pngaaacom5421128.png'),
  StripeIconSquare: require('../assets/images/StripeIconSquare.png'),
  Logo: require('../assets/images/Logo.png'),
  LogoOnly: require('../assets/images/LogoOnly.png'),
  Welcome2: require('../assets/images/Welcome2.png'),
  Welcome3: require('../assets/images/Welcome3.png'),
  Wallet: require('../assets/images/Wallet.png'),
  Addresses: require('../assets/images/Addresses.png'),
  Transfers: require('../assets/images/Transfers.png'),
  WihteLogoMailboxx: require('../assets/images/WihteLogoMailboxx.png'),
  DarkLogoMailboxx: require('../assets/images/DarkLogoMailboxx.png'),
  DarkLogoOnlyMailboxx: require('../assets/images/DarkLogoOnlyMailboxx.png'),
  PngColorLogoNoBackground1: require('../assets/images/PngColorLogoNoBackground(1).png'),
  PlaystoreButton: require('../assets/images/PlaystoreButton.png'),
  ApplestoreButton: require('../assets/images/ApplestoreButton.png'),
  Welcome1: require('../assets/images/Welcome1.gif'),
  Welcome2White: require('../assets/images/Welcome2White.gif'),
  Welcome3Teal: require('../assets/images/Welcome3Teal.gif'),
  Welcome4Black: require('../assets/images/Welcome4Black.gif'),
  MailboxxGreenLogo: require('../assets/images/MailboxxGreenLogo.png'),
  CategoriesPromotion: require('../assets/images/CategoriesPromotion.png'),
  DirectInbox: require('../assets/images/DirectInbox.png'),
  ExplorePromotion: require('../assets/images/ExplorePromotion.png'),
  ImageAsset: require('../assets/images/ImageAsset.jpg'),
  VideoAsset: require('../assets/images/VideoAsset.jpg'),
  InboxGlobal: require('../assets/images/InboxGlobal.jpg'),
  InboxGps: require('../assets/images/InboxGps.jpg'),
  InboxZip: require('../assets/images/InboxZip.jpg'),
  NearByPromotionsMin: require('../assets/images/NearByPromotionsMin.jpg'),
  NationalPromotionsMin: require('../assets/images/NationalPromotionsMin.jpg'),
  adobeexpresslogo: require('../assets/images/AdobeExpressLogo.png'),
  unfollowicon: require('../assets/images/UnfollowIcon.png'),
  followicon: require('../assets/images/FollowIcon.png'),
  contactimport: require('../assets/images/ContactImport.png'),
};
