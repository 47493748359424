import React from 'react';
import {
  Button,
  Icon,
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  Swiper,
  SwiperItem,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import * as WebBrowser from 'expo-web-browser';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import getPercent from '../global-functions/getPercent';
import isLoggedIn from '../global-functions/isLoggedIn';
import trackAction from '../global-functions/trackAction';
import trackScreen from '../global-functions/trackScreen';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const WelcomeScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const segment = useAnalytics();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      trackScreen(segment, 'Welcome');
      if (!isLoggedIn(Variables)) {
        return;
      }
      /* hidden 'If/Else' action */
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.White },
        dimensions.width
      )}
    >
      {/* Wide_Screen */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <View
              {...GlobalStyles.ViewStyles(theme)['ScreenRow'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['ScreenRow'].style,
                  {
                    alignItems: 'center',
                    backgroundColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: theme.colors.branding.primary,
                      },
                      {
                        minWidth: Breakpoints.Tablet,
                        value: theme.colors.branding.primary,
                      },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {/* ScreenRow */}
              <View
                {...GlobalStyles.ViewStyles(theme)['ScreenRow'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['ScreenRow'].style,
                    {
                      backgroundColor: {
                        minWidth: Breakpoints.Tablet,
                        value: theme.colors.branding.primary,
                      },
                      maxWidth: 1024,
                      minHeight: {
                        minWidth: Breakpoints.Tablet,
                        value: dimensions.height,
                      },
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* SubRow */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: {
                        minWidth: Breakpoints.Tablet,
                        value: 'row',
                      },
                      justifyContent: {
                        minWidth: Breakpoints.Tablet,
                        value: 'flex-end',
                      },
                      padding: { minWidth: Breakpoints.Tablet, value: 16 },
                    },
                    dimensions.width
                  )}
                >
                  <Pressable
                    onPress={() => {
                      try {
                        navigation.navigate('AuthLoginScreen');
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                  >
                    {/* LoginBtn */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                          backgroundColor: {
                            minWidth: Breakpoints.Tablet,
                            value: palettes.App.PrimaryDark,
                          },
                          borderBottomLeftRadius: {
                            minWidth: Breakpoints.Tablet,
                            value: 25,
                          },
                          borderBottomRightRadius: {
                            minWidth: Breakpoints.Tablet,
                            value: 25,
                          },
                          borderTopLeftRadius: {
                            minWidth: Breakpoints.Tablet,
                            value: 25,
                          },
                          borderTopRightRadius: {
                            minWidth: Breakpoints.Tablet,
                            value: 25,
                          },
                          height: { minWidth: Breakpoints.Tablet, value: 50 },
                          justifyContent: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                          width: { minWidth: Breakpoints.Tablet, value: 150 },
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: {
                                minWidth: Breakpoints.Tablet,
                                value: theme.colors.background.brand,
                              },
                              fontFamily: {
                                minWidth: Breakpoints.Tablet,
                                value: 'Poppins_600SemiBold',
                              },
                              fontSize: 22,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'LOGIN'}
                      </Text>
                    </View>
                  </Pressable>
                  {/* Pressable 2 */}
                  <Pressable
                    onPress={() => {
                      try {
                        navigation.navigate('AuthSelectSignupTypeScreen');
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                  >
                    {/* Signup */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                          backgroundColor: {
                            minWidth: Breakpoints.Tablet,
                            value: 'rgba(0, 0, 0, 0)',
                          },
                          borderBottomLeftRadius: {
                            minWidth: Breakpoints.Tablet,
                            value: 25,
                          },
                          borderBottomRightRadius: {
                            minWidth: Breakpoints.Tablet,
                            value: 25,
                          },
                          borderTopLeftRadius: {
                            minWidth: Breakpoints.Tablet,
                            value: 25,
                          },
                          borderTopRightRadius: {
                            minWidth: Breakpoints.Tablet,
                            value: 25,
                          },
                          height: { minWidth: Breakpoints.Tablet, value: 50 },
                          justifyContent: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                          width: { minWidth: Breakpoints.Tablet, value: 150 },
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: {
                                minWidth: Breakpoints.Tablet,
                                value: theme.colors.background.brand,
                              },
                              fontFamily: {
                                minWidth: Breakpoints.Tablet,
                                value: 'Poppins_600SemiBold',
                              },
                              fontSize: 22,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'SIGN UP'}
                      </Text>
                    </View>
                  </Pressable>
                </View>
                {/* SubRow */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Tablet, value: 1 },
                      flexDirection: {
                        minWidth: Breakpoints.Tablet,
                        value: 'row',
                      },
                    },
                    dimensions.width
                  )}
                >
                  {/* LeftCol */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flex: { minWidth: Breakpoints.Tablet, value: 1 },
                        justifyContent: {
                          minWidth: Breakpoints.Tablet,
                          value: 'space-evenly',
                        },
                        padding: { minWidth: Breakpoints.Tablet, value: 48 },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Logo */}
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={imageSource(Images['WihteLogoMailboxx'])}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          {
                            width: { minWidth: Breakpoints.Tablet, value: 300 },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                    {/* View 2 */}
                    <View>
                      {/* Title */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: {
                                minWidth: Breakpoints.Tablet,
                                value: theme.colors.background.brand,
                              },
                              fontSize: {
                                minWidth: Breakpoints.Tablet,
                                value: 64,
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Your Privacy, Our Priority'}
                      </Text>
                      {/* Subtitle */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: {
                                minWidth: Breakpoints.Tablet,
                                value: theme.colors.background.brand,
                              },
                              fontSize: {
                                minWidth: Breakpoints.Tablet,
                                value: 22,
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'Experience a new way to receive personalized content from businesses you love. Our innovative platform ensures that you get the most relevant offers, updates, and information tailored just for you.'
                        }
                      </Text>
                    </View>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                          borderBottomLeftRadius: {
                            minWidth: Breakpoints.Tablet,
                            value: 25,
                          },
                          borderBottomRightRadius: {
                            minWidth: Breakpoints.Tablet,
                            value: 25,
                          },
                          borderTopLeftRadius: {
                            minWidth: Breakpoints.Tablet,
                            value: 25,
                          },
                          borderTopRightRadius: {
                            minWidth: Breakpoints.Tablet,
                            value: 25,
                          },
                          height: [
                            { minWidth: Breakpoints.Tablet, value: 30 },
                            { minWidth: Breakpoints.Laptop, value: 40 },
                          ],
                          justifyContent: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                          width: { minWidth: Breakpoints.Tablet, value: 150 },
                        },
                        dimensions.width
                      )}
                    ></View>
                  </View>
                  {/* RightCol */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        flex: { minWidth: Breakpoints.Tablet, value: 1 },
                        justifyContent: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={imageSource(Images['Welcome1'])}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          {
                            height: {
                              minWidth: Breakpoints.Tablet,
                              value: 500,
                            },
                            width: { minWidth: Breakpoints.Tablet, value: 500 },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                </View>
              </View>
            </View>
            {/* View 3 */}
            <View
              {...GlobalStyles.ViewStyles(theme)['ScreenRow'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['ScreenRow'].style,
                  {
                    alignItems: 'center',
                    backgroundColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: theme.colors.background.brand,
                      },
                      {
                        minWidth: Breakpoints.Tablet,
                        value: palettes.App.WelcomeBgSecondary,
                      },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {/* ScreenRow 3 */}
              <View
                {...GlobalStyles.ViewStyles(theme)['ScreenRow'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['ScreenRow'].style,
                    {
                      backgroundColor: {
                        minWidth: Breakpoints.Tablet,
                        value: palettes.App.WelcomeBgSecondary,
                      },
                      maxWidth: 1024,
                      minHeight: {
                        minWidth: Breakpoints.Tablet,
                        value: dimensions.height,
                      },
                      padding: { minWidth: Breakpoints.Tablet, value: 36 },
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* SubRow */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: { minWidth: Breakpoints.Tablet, value: 1 } },
                    dimensions.width
                  )}
                >
                  {/* Title */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: {
                            minWidth: Breakpoints.Tablet,
                            value: '"rgb(29, 205, 141)"',
                          },
                          fontFamily: {
                            minWidth: Breakpoints.Tablet,
                            value: 'Poppins_700Bold',
                          },
                          fontSize: { minWidth: Breakpoints.Tablet, value: 40 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Our Services'}
                  </Text>

                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: {
                            minWidth: Breakpoints.Tablet,
                            value: theme.colors.background.brand,
                          },
                          fontSize: { minWidth: Breakpoints.Tablet, value: 22 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'At Mailboxx, we prioritize your privacy while revolutionizing your mail experience. Transition effortlessly to a digital mailbox where your information is protected and your communication remains secure. Receive eco-friendly messages tailored to your locality without compromising on confidentiality. Businesses can leverage our localized targeting tools to reach their audience effectively, with the assurance that user data is handled with the utmost care. Our advanced analytics enable brands to monitor campaign success and refine strategies in a privacy-centric environment, all while supporting a greener tomorrow. With Mailboxx, your security and privacy come first.'
                    }
                  </Text>
                </View>
                {/* SubRow 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Tablet, value: 1 },
                      flexDirection: {
                        minWidth: Breakpoints.Tablet,
                        value: 'row',
                      },
                    },
                    dimensions.width
                  )}
                >
                  {/* Col */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Tablet,
                          value: 'flex-start',
                        },
                        backgroundColor: {
                          minWidth: Breakpoints.Tablet,
                          value: palettes.App.PrimaryDark,
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Tablet,
                          value: 25,
                        },
                        flex: { minWidth: Breakpoints.Tablet, value: 1 },
                        justifyContent: {
                          minWidth: Breakpoints.Tablet,
                          value: 'flex-start',
                        },
                        margin: { minWidth: Breakpoints.Tablet, value: 12 },
                        marginLeft: { minWidth: Breakpoints.Tablet, value: 0 },
                        padding: { minWidth: Breakpoints.Tablet, value: 20 },
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          height: { minWidth: Breakpoints.Laptop, value: 170 },
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={palettes.App['Custom Color_18']}
                        name={'MaterialIcons/eco'}
                        size={65}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: {
                              minWidth: Breakpoints.Tablet,
                              value: 12,
                            },
                          },
                          dimensions.width
                        )}
                      />
                      {/* Title 2 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: {
                                minWidth: Breakpoints.Tablet,
                                value: '"rgb(29, 205, 141)"',
                              },
                              fontFamily: {
                                minWidth: Breakpoints.Tablet,
                                value: 'Poppins_700Bold',
                              },
                              fontSize: {
                                minWidth: Breakpoints.Tablet,
                                value: 26,
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Eco-Friendly Evolution'}
                      </Text>
                    </View>
                    {/* Text 2 */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: {
                              minWidth: Breakpoints.Tablet,
                              value: theme.colors.background.brand,
                            },
                            fontSize: {
                              minWidth: Breakpoints.Tablet,
                              value: 18,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'Mailboxx delivers digital messages, eliminating wasteful paper and promoting a greener future.'
                      }
                    </Text>
                  </View>
                  {/* Col 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Tablet,
                          value: 'flex-start',
                        },
                        backgroundColor: {
                          minWidth: Breakpoints.Tablet,
                          value: palettes.App.PrimaryDark,
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Tablet,
                          value: 25,
                        },
                        flex: { minWidth: Breakpoints.Tablet, value: 1 },
                        justifyContent: {
                          minWidth: Breakpoints.Tablet,
                          value: 'flex-start',
                        },
                        margin: { minWidth: Breakpoints.Tablet, value: 12 },
                        padding: { minWidth: Breakpoints.Tablet, value: 20 },
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          height: { minWidth: Breakpoints.Laptop, value: 170 },
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={palettes.App['Custom Color_18']}
                        name={'MaterialIcons/local-florist'}
                        size={65}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: {
                              minWidth: Breakpoints.Tablet,
                              value: 12,
                            },
                          },
                          dimensions.width
                        )}
                      />
                      {/* Title 2 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: {
                                minWidth: Breakpoints.Tablet,
                                value: '"rgb(29, 205, 141)"',
                              },
                              fontFamily: {
                                minWidth: Breakpoints.Tablet,
                                value: 'Poppins_700Bold',
                              },
                              fontSize: {
                                minWidth: Breakpoints.Tablet,
                                value: 26,
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Local and Relevant'}
                      </Text>
                    </View>
                    {/* Text 2 */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: {
                              minWidth: Breakpoints.Tablet,
                              value: theme.colors.background.brand,
                            },
                            fontSize: {
                              minWidth: Breakpoints.Tablet,
                              value: 18,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'Mailboxx helps businesses send tailored offers and information to your local area, reducing irrelevant messages from far-off places.'
                      }
                    </Text>
                  </View>
                  {/* Col 3 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Tablet,
                          value: 'flex-start',
                        },
                        backgroundColor: {
                          minWidth: Breakpoints.Tablet,
                          value: palettes.App.PrimaryDark,
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Tablet,
                          value: 25,
                        },
                        flex: { minWidth: Breakpoints.Tablet, value: 1 },
                        justifyContent: {
                          minWidth: Breakpoints.Tablet,
                          value: 'flex-start',
                        },
                        margin: { minWidth: Breakpoints.Tablet, value: 12 },
                        marginRight: { minWidth: Breakpoints.Tablet, value: 0 },
                        padding: { minWidth: Breakpoints.Tablet, value: 20 },
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          height: { minWidth: Breakpoints.Laptop, value: 170 },
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={palettes.App['Custom Color_18']}
                        name={'MaterialCommunityIcons/lightbulb-on'}
                        size={65}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: {
                              minWidth: Breakpoints.Tablet,
                              value: 12,
                            },
                          },
                          dimensions.width
                        )}
                      />
                      {/* Title 2 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: {
                                minWidth: Breakpoints.Tablet,
                                value: '"rgb(29, 205, 141)"',
                              },
                              fontFamily: {
                                minWidth: Breakpoints.Tablet,
                                value: 'Poppins_700Bold',
                              },
                              fontSize: {
                                minWidth: Breakpoints.Tablet,
                                value: 26,
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Enhanced User Experience'}
                      </Text>
                    </View>
                    {/* Text 2 */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: {
                              minWidth: Breakpoints.Tablet,
                              value: theme.colors.background.brand,
                            },
                            fontSize: {
                              minWidth: Breakpoints.Tablet,
                              value: 18,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'Mailboxx streamlines your digital mail, offering an intuitive and efficient way to manage your messages and stay updated on local offers, all within a secure and user-friendly platform.'
                      }
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            {/* View 2 */}
            <View
              {...GlobalStyles.ViewStyles(theme)['ScreenRow'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['ScreenRow'].style,
                  {
                    alignItems: 'center',
                    backgroundColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: theme.colors.background.brand,
                      },
                      {
                        minWidth: Breakpoints.Tablet,
                        value: theme.colors.background.brand,
                      },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {/* ScreenRow 2 */}
              <View
                {...GlobalStyles.ViewStyles(theme)['ScreenRow'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['ScreenRow'].style,
                    {
                      backgroundColor: {
                        minWidth: Breakpoints.Tablet,
                        value: theme.colors.background.brand,
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Tablet,
                        value: 'row',
                      },
                      maxWidth: 1024,
                      minHeight: {
                        minWidth: Breakpoints.Tablet,
                        value: dimensions.height,
                      },
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* Col */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Tablet,
                        value: 'center',
                      },
                      flex: { minWidth: Breakpoints.Tablet, value: 1 },
                      justifyContent: {
                        minWidth: Breakpoints.Tablet,
                        value: 'center',
                      },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: {
                            minWidth: Breakpoints.Tablet,
                            value: theme.colors.text.strong,
                          },
                          fontFamily: {
                            minWidth: Breakpoints.Tablet,
                            value: 'Poppins_700Bold',
                          },
                          fontSize: { minWidth: Breakpoints.Tablet, value: 28 },
                          textAlign: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Ditch the Physical, Embrace the \nDigital with Mailboxx.'}
                  </Text>
                  <Image
                    resizeMode={'cover'}
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    source={imageSource(Images['Welcome2White'])}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          height: { minWidth: Breakpoints.Tablet, value: 500 },
                          width: { minWidth: Breakpoints.Tablet, value: 500 },
                        }
                      ),
                      dimensions.width
                    )}
                  />
                </View>
              </View>
            </View>
            {/* View 4 */}
            <View
              {...GlobalStyles.ViewStyles(theme)['ScreenRow'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['ScreenRow'].style,
                  {
                    alignItems: 'center',
                    backgroundColor: {
                      minWidth: Breakpoints.Tablet,
                      value: '"rgb(205, 243, 229)"',
                    },
                  }
                ),
                dimensions.width
              )}
            >
              {/* ScreenRow 4 */}
              <View
                {...GlobalStyles.ViewStyles(theme)['ScreenRow'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['ScreenRow'].style,
                    {
                      backgroundColor: {
                        minWidth: Breakpoints.Tablet,
                        value: '"rgb(205, 243, 229)"',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Tablet,
                        value: 'row',
                      },
                      maxWidth: 1024,
                      minHeight: {
                        minWidth: Breakpoints.Tablet,
                        value: dimensions.height,
                      },
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* Col */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Tablet,
                        value: 'center',
                      },
                      flex: { minWidth: Breakpoints.Tablet, value: 1 },
                      justifyContent: {
                        minWidth: Breakpoints.Tablet,
                        value: 'center',
                      },
                      padding: { minWidth: Breakpoints.Tablet, value: 20 },
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Tablet,
                          value: 'flex-start',
                        },
                        backgroundColor: {
                          minWidth: Breakpoints.Tablet,
                          value: theme.colors.background.brand,
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Tablet,
                          value: 25,
                        },
                        padding: { minWidth: Breakpoints.Tablet, value: 20 },
                        paddingBottom: {
                          minWidth: Breakpoints.Tablet,
                          value: 50,
                        },
                        paddingTop: { minWidth: Breakpoints.Tablet, value: 50 },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: {
                              minWidth: Breakpoints.Tablet,
                              value: theme.colors.text.strong,
                            },
                            fontFamily: {
                              minWidth: Breakpoints.Tablet,
                              value: 'Poppins_700Bold',
                            },
                            fontSize: {
                              minWidth: Breakpoints.Tablet,
                              value: 22,
                            },
                            marginBottom: {
                              minWidth: Breakpoints.Tablet,
                              value: 20,
                            },
                            textAlign: {
                              minWidth: Breakpoints.Tablet,
                              value: 'center',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Targeted Precision'}
                    </Text>
                    {/* Text 2 */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: {
                              minWidth: Breakpoints.Tablet,
                              value: theme.colors.text.strong,
                            },
                            fontSize: {
                              minWidth: Breakpoints.Tablet,
                              value: 16,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        "Say goodbye to the scattergun approach of traditional mail. With our advanced algorithms, Mailboxx ensures you only receive messages that matter to you. It's time to experience the future of mail – targeted, precise, and always relevant"
                      }
                    </Text>
                  </View>
                </View>
                {/* Col 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Tablet,
                        value: 'center',
                      },
                      flex: { minWidth: Breakpoints.Tablet, value: 1 },
                      justifyContent: {
                        minWidth: Breakpoints.Tablet,
                        value: 'center',
                      },
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    source={imageSource(Images['Welcome3Teal'])}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          height: { minWidth: Breakpoints.Tablet, value: 500 },
                          width: { minWidth: Breakpoints.Tablet, value: 500 },
                        }
                      ),
                      dimensions.width
                    )}
                  />
                </View>
              </View>
            </View>
            {/* View 5 */}
            <View
              {...GlobalStyles.ViewStyles(theme)['ScreenRow'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['ScreenRow'].style,
                  {
                    alignItems: 'center',
                    backgroundColor: {
                      minWidth: Breakpoints.Tablet,
                      value: palettes.App.Black,
                    },
                  }
                ),
                dimensions.width
              )}
            >
              {/* ScreenRow 5 */}
              <View
                {...GlobalStyles.ViewStyles(theme)['ScreenRow'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['ScreenRow'].style,
                    {
                      backgroundColor: {
                        minWidth: Breakpoints.Tablet,
                        value: palettes.App.Black,
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Tablet,
                        value: 'row',
                      },
                      maxWidth: 1024,
                      minHeight: {
                        minWidth: Breakpoints.Tablet,
                        value: dimensions.height,
                      },
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* Col 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Tablet,
                        value: 'center',
                      },
                      flex: { minWidth: Breakpoints.Tablet, value: 1 },
                      justifyContent: {
                        minWidth: Breakpoints.Tablet,
                        value: 'center',
                      },
                      marginRight: 20,
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    source={imageSource(Images['Welcome4Black'])}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          height: { minWidth: Breakpoints.Tablet, value: 500 },
                          width: { minWidth: Breakpoints.Tablet, value: 500 },
                        }
                      ),
                      dimensions.width
                    )}
                  />
                </View>
                {/* Col */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Tablet,
                        value: 'flex-start',
                      },
                      flex: { minWidth: Breakpoints.Tablet, value: 1 },
                      justifyContent: {
                        minWidth: Breakpoints.Tablet,
                        value: 'center',
                      },
                      padding: { minWidth: Breakpoints.Tablet, value: 20 },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: {
                            minWidth: Breakpoints.Tablet,
                            value: theme.colors.background.brand,
                          },
                          fontFamily: {
                            minWidth: Breakpoints.Tablet,
                            value: 'Poppins_700Bold',
                          },
                          fontSize: { minWidth: Breakpoints.Tablet, value: 40 },
                          marginBottom: {
                            minWidth: Breakpoints.Tablet,
                            value: 20,
                          },
                          textAlign: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Contact Us'}
                  </Text>
                  {/* Text 3 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: {
                            minWidth: Breakpoints.Tablet,
                            value: palettes.App['Custom Color_18'],
                          },
                          fontFamily: {
                            minWidth: Breakpoints.Tablet,
                            value: 'Poppins_700Bold',
                          },
                          fontSize: { minWidth: Breakpoints.Tablet, value: 24 },
                          marginBottom: {
                            minWidth: Breakpoints.Tablet,
                            value: 20,
                          },
                          textAlign: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Email'}
                  </Text>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: {
                            minWidth: Breakpoints.Tablet,
                            value: theme.colors.background.brand,
                          },
                          fontSize: { minWidth: Breakpoints.Tablet, value: 16 },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'help@mailboxx.app'}
                  </Text>
                  {/* Text 4 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: {
                            minWidth: Breakpoints.Tablet,
                            value: palettes.App['Custom Color_18'],
                          },
                          fontFamily: {
                            minWidth: Breakpoints.Tablet,
                            value: 'Poppins_700Bold',
                          },
                          fontSize: { minWidth: Breakpoints.Tablet, value: 24 },
                          marginBottom: {
                            minWidth: Breakpoints.Tablet,
                            value: 20,
                          },
                          marginTop: {
                            minWidth: Breakpoints.Tablet,
                            value: 20,
                          },
                          textAlign: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Social'}
                  </Text>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Tablet,
                          value: 'row',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Facebook */}
                    <IconButton
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              'https://www.facebook.com/profile.php?id=61563609514067'
                            );
                          } catch (err) {
                            Sentry.captureException(err);
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      size={32}
                      color={theme.colors.background.brand}
                      icon={'AntDesign/facebook-square'}
                      style={StyleSheet.applyWidth(
                        {
                          marginRight: {
                            minWidth: Breakpoints.Tablet,
                            value: 12,
                          },
                        },
                        dimensions.width
                      )}
                    />
                    {/* Twitter */}
                    <IconButton
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              'https://x.com/MailboxxUSA'
                            );
                          } catch (err) {
                            Sentry.captureException(err);
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      size={32}
                      color={theme.colors.background.brand}
                      icon={'AntDesign/twitter'}
                      style={StyleSheet.applyWidth(
                        {
                          marginRight: {
                            minWidth: Breakpoints.Tablet,
                            value: 12,
                          },
                        },
                        dimensions.width
                      )}
                    />
                    {/* Instagram */}
                    <IconButton
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              'https://www.instagram.com/mailboxxapp/'
                            );
                          } catch (err) {
                            Sentry.captureException(err);
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      size={32}
                      color={theme.colors.background.brand}
                      icon={'AntDesign/instagram'}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        )}
      </>
      {/* Narrow_Screen */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View>
            <Swiper
              data={Constants['silder_data']}
              dotColor={theme.colors.text.light}
              dotsTouchable={true}
              keyExtractor={(swiperData, index) =>
                swiperData?.id ??
                swiperData?.uuid ??
                index?.toString() ??
                JSON.stringify(swiperData)
              }
              listKey={'neIpvvFu'}
              loop={false}
              minDistanceForAction={0.2}
              minDistanceToCapture={5}
              onSwipedNext={swipedIndex => {
                try {
                  trackAction(Variables, segment, 'Swiped Next', undefined);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              onSwipedPrevious={swipedIndex => {
                try {
                  trackAction(Variables, segment, 'Swiped Previous', undefined);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              renderItem={({ item, index }) => {
                const swiperData = item;
                return (
                  <SwiperItem
                    style={StyleSheet.applyWidth(
                      { justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { borderStyle: 'solid' },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 36,
                              lineHeight: 40,
                              margin: 20,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {swiperData?.title}
                      </Text>
                      <SimpleStyleFlatList
                        data={swiperData?.texts}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ??
                          listData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listData)
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={JSON.stringify(swiperData?.texts)}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  marginBottom: 20,
                                  marginLeft: 30,
                                  marginRight: 30,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Title */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      fontFamily: 'Poppins_500Medium',
                                      fontSize: 16,
                                      marginBottom: 10,
                                      marginLeft: 10,
                                      marginRight: 10,
                                      textAlign: 'left',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData?.subtitle}
                              </Text>

                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      marginLeft: 10,
                                      marginRight: 10,
                                      textAlign: 'left',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData?.content}
                              </Text>
                            </View>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                      />
                    </View>
                  </SwiperItem>
                );
              }}
              timeout={0}
              {...GlobalStyles.SwiperStyles(theme)['Swiper'].props}
              dotActiveColor={theme.colors.branding.primary}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.SwiperStyles(theme)['Swiper'].style,
                  {
                    alignSelf: 'center',
                    height: [
                      { minWidth: Breakpoints.Mobile, value: null },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: getPercent(dimensions.height, 80),
                      },
                    ],
                    marginTop: 80,
                    maxWidth: 1024,
                  }
                ),
                dimensions.width
              )}
              vertical={false}
            />
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingTop: 30,
                },
                dimensions.width
              )}
            >
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    trackAction(
                      Variables,
                      segment,
                      'Clicked Continue Button',
                      undefined
                    );
                    navigation.navigate('AuthLoginScreen');
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: theme.colors.branding.primary,
                      width: '80%',
                    }
                  ),
                  dimensions.width
                )}
                title={'Continue'}
              />
            </View>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(WelcomeScreen);
